import {Config} from "@co-common-libs/config";
import {
  Contact,
  ContactUrl,
  CultureUrl,
  Customer,
  CustomerUrl,
  DaysAbsence,
  EmployeeGroup,
  HoursAbsence,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PatchOperation,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUseWithOrder,
  PriceItemUsesDict,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUse,
  ReportingSpecification,
  ReportingSpecificationUrl,
  ResourceInstance,
  ResourceTypeUnion,
  Role,
  RoutePlan,
  RoutePlanUrl,
  SprayLog,
  Task,
  TimeCorrection,
  Timer,
  TimerStart,
  TimerUrl,
  TransportLog,
  Unit,
  UnitUrl,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  YieldLog,
  applyPatch,
  emptyOrder,
  emptyTask,
  urlToId,
} from "@co-common-libs/resources";
import {
  allLogLocationsInFieldUses,
  getExpectedLogSpecification,
  getNormalisedDeviceTimestamp,
  getTaskTimersWithTime,
  logChangeLegal,
  machinePotentialPriceGroups,
  priceItemIsManualDistributionTime,
  recomputePriceGroup,
  sortLegacyProductUseListByCatalogNumber,
  willRemovalChangeLogSpecification,
  workTypePotentialPriceGroups,
} from "@co-common-libs/resources-utils";
import {dateToString, notUndefined, sortByOrderMember} from "@co-common-libs/utils";
import {patchFromPriceItemUsesChange, recomputePriceItemUses} from "@co-frontend-libs/utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import memoizeOne from "memoize-one";
import {IntlShape, defineMessages} from "react-intl";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {InlinedOrder, InlinedTask} from "./inline-data";
import {changeCustomerCulture} from "./order-helpers";
import {padZero} from "./pad-zero";
import {computeIntervalSums, computeIntervalsTruncated, mergeIntervals} from "./task-timers";
import {getExternalSecondaryTimers, getInternalSecondaryTimers} from "./timers";

const getMachineUsePriceGroupURLList = (task: InlinedTask | Task): PriceGroupUrl[] => {
  const result: PriceGroupUrl[] = [];
  ((task.machineuseSet || []) as readonly (InlinedTask["machineuseSet"][0] | MachineUse)[]).forEach(
    (machineUse) => {
      const machinePriceGroupURL = machineUse.priceGroup;
      if (machinePriceGroupURL) {
        if (typeof machinePriceGroupURL === "string") {
          result.push(machinePriceGroupURL);
        } else {
          result.push(machinePriceGroupURL.url);
        }
      }
    },
  );
  return result;
};

const getWorktypeExtraTimerList = (
  task: InlinedTask | Task,
  customerSettings: Config,
  {
    timerArray,
    workTypeLookup,
  }: {
    timerArray: readonly Timer[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): Timer[] => {
  if (!Object.keys(customerSettings.workTypeExtraTimers).length) {
    return [];
  }
  const workTypeURL = task.workType;
  if (!workTypeURL) {
    return [];
  }
  const workType = typeof workTypeURL === "string" ? workTypeLookup(workTypeURL) : workTypeURL;
  if (!workType) {
    return [];
  }
  const workTypeIdentifier = workType.identifier;
  const extraTimerIdentifierList = customerSettings.workTypeExtraTimers[workTypeIdentifier];
  if (extraTimerIdentifierList) {
    return extraTimerIdentifierList
      .map((timerIdentifier) => timerArray.find((t) => t.identifier === timerIdentifier))
      .filter(notUndefined);
  }
  return [];
};

const getMachineExtraTimerList = (
  task: InlinedTask | Task,
  customerSettings: Config,
  {
    machineLookup,
    timerArray,
  }: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    timerArray: readonly Timer[];
  },
): Timer[] => {
  if (!Object.keys(customerSettings.machineExtraTimers).length) {
    return [];
  }
  const machineUseSet = task.machineuseSet;
  if (!machineUseSet || !machineUseSet.length) {
    return [];
  }
  const machineList = (machineUseSet as readonly (InlinedTask["machineuseSet"][0] | MachineUse)[])
    .map((machineUse) => {
      const machineURL = machineUse.machine;
      const machine =
        typeof machineURL === "string" ? machineLookup(machineURL) : machineURL || undefined;
      return machine;
    })
    .filter(notUndefined);
  const extraTimerIdentifierListList = machineList
    .map((machine) => {
      const machineIdentifier = machine.c5_machine;
      return customerSettings.machineExtraTimers[machineIdentifier];
    })
    .filter(notUndefined);
  if (!extraTimerIdentifierListList.length) {
    return [];
  }
  return _.flatten(extraTimerIdentifierListList)
    .map((timerIdentifier) => timerArray.find((t) => t.identifier === timerIdentifier))
    .filter(notUndefined);
};

const getWorktypeMachineExtraTimerList = (
  task: InlinedTask | Task,
  customerSettings: Config,
  {
    machineLookup,
    timerArray,
    workTypeLookup,
  }: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    timerArray: readonly Timer[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): Timer[] => {
  if (!Object.keys(customerSettings.workTypeMachineExtraTimers)) {
    return [];
  }
  const workTypeURL = task.workType;
  if (!workTypeURL) {
    return [];
  }
  const workType = typeof workTypeURL === "string" ? workTypeLookup(workTypeURL) : workTypeURL;
  if (!workType) {
    return [];
  }
  const workTypeIdentifier = workType.identifier;
  const machineTimerIdentifierMap = customerSettings.workTypeMachineExtraTimers[workTypeIdentifier];
  if (machineTimerIdentifierMap) {
    const machineUseList = task.machineuseSet || [];
    const currentMachineIdentifierSet = new Set(
      (machineUseList as readonly (InlinedTask["machineuseSet"][0] | MachineUse)[]).map(
        (machineUse) => {
          const machineURL = machineUse.machine;
          const machine = typeof machineURL === "string" ? machineLookup(machineURL) : machineURL;
          const machineID = machine && machine.c5_machine;
          return machineID;
        },
      ),
    );
    const extraTimerIdentifierList = _.flatten(
      Object.entries(machineTimerIdentifierMap)
        .filter(([machineID, _timerIDList]) => currentMachineIdentifierSet.has(machineID))
        .map(([_machineID, timerIDList]) => timerIDList)
        .filter(notUndefined),
    );
    return extraTimerIdentifierList
      .map((timerIdentifier) => timerArray.find((t) => t.identifier === timerIdentifier))
      .filter(notUndefined);
  }
  return [];
};

const getWorkTypePriceGroupExtraTimerList = (
  task: InlinedTask | Task,
  customerSettings: Config,
  {
    priceGroupLookup,
    timerArray,
    workTypeLookup,
  }: {
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    timerArray: readonly Timer[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): Timer[] => {
  if (!Object.keys(customerSettings.workTypePriceGroupExtraTimers).length) {
    return [];
  }
  const {workType} = task;
  if (!workType) {
    return [];
  }
  let {priceGroup} = task;
  if (!priceGroup) {
    const machineUsePriceGroupURLList = getMachineUsePriceGroupURLList(task);
    if (machineUsePriceGroupURLList.length === 1) {
      priceGroup = machineUsePriceGroupURLList[0];
    }
  }
  if (!priceGroup) {
    return [];
  }
  const workTypeInstance = typeof workType === "string" ? workTypeLookup(workType) : workType;
  if (!workTypeInstance) {
    return [];
  }
  const workTypeIdentifier = workTypeInstance.identifier;
  const priceGroupTimerIdentifierMap =
    customerSettings.workTypePriceGroupExtraTimers[workTypeIdentifier];
  if (priceGroupTimerIdentifierMap) {
    const priceGroupInstance =
      typeof priceGroup === "string" ? priceGroupLookup(priceGroup) : priceGroup;
    if (!priceGroupInstance) {
      return [];
    }
    const priceGroupIdentifier = priceGroupInstance.identifier;
    const extraTimerIdentifierList = priceGroupTimerIdentifierMap[priceGroupIdentifier];
    if (!extraTimerIdentifierList) {
      return [];
    }
    return extraTimerIdentifierList
      .map((timerIdentifier) => timerArray.find((t) => t.identifier === timerIdentifier))
      .filter(notUndefined);
  }
  return [];
};

const getMachinePriceGroupExtraTimerList = (
  task: InlinedTask | Task,
  customerSettings: Config,
  {
    machineLookup,
    priceGroupLookup,
    timerArray,
  }: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    timerArray: readonly Timer[];
  },
): Timer[] => {
  if (!Object.keys(customerSettings.machinePriceGroupExtraTimers).length) {
    return [];
  }
  const {machineuseSet} = task;
  if (!machineuseSet.length) {
    return [];
  }
  const extraTimers = new Map<string, Timer>();

  machineuseSet.forEach(
    (
      machineUse:
        | MachineUse
        | {
            machine: Machine | null;
            priceGroup: PriceGroup | null;
            transporter: boolean;
          },
    ) => {
      let machineIdentifier;
      const machineOrUrl = machineUse.machine;
      if (machineOrUrl) {
        if (typeof machineOrUrl === "string") {
          const machine = machineLookup(machineOrUrl);
          machineIdentifier = machine?.c5_machine;
        } else {
          machineIdentifier = machineOrUrl?.c5_machine;
        }
      }
      if (!machineIdentifier) {
        return;
      }
      const priceGroupTimerIdentifierMap =
        customerSettings.machinePriceGroupExtraTimers[machineIdentifier];
      if (!priceGroupTimerIdentifierMap || _.isEmpty(priceGroupTimerIdentifierMap)) {
        return;
      }
      const {priceGroup} = machineUse;
      const priceGroupInstance =
        typeof priceGroup === "string" ? priceGroupLookup(priceGroup) : priceGroup;
      if (!priceGroupInstance) {
        return;
      }
      const priceGroupIdentifier = priceGroupInstance.identifier;
      const extraTimerIdentifierList = priceGroupTimerIdentifierMap[priceGroupIdentifier];
      if (!extraTimerIdentifierList) {
        return;
      }

      extraTimerIdentifierList
        .map((timerIdentifier) => timerArray.find((t) => t.identifier === timerIdentifier))
        .filter(notUndefined)
        .forEach((timer) => extraTimers.set(timer.url, timer));
    },
  );

  return Array.from(extraTimers.values());
};

const getDepartmentExtraTimerList = (
  task: InlinedTask | Task,
  customerSettings: Config,
  timerArray: readonly Timer[],
): Timer[] => {
  if (!Object.keys(customerSettings.departmentExtraTimers).length) {
    return [];
  }
  const {department} = task;
  if (!department) {
    return [];
  }
  const extraTimerIdentifierList = customerSettings.departmentExtraTimers[department];
  if (extraTimerIdentifierList) {
    return extraTimerIdentifierList
      .map((timerIdentifier) => timerArray.find((t) => t.identifier === timerIdentifier))
      .filter(notUndefined);
  }
  return [];
};

const filterPriceGroupHiddenTimers = (
  secondaryTimers: Timer[],
  task: InlinedTask | Task,
  customerSettings: Config,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): Timer[] => {
  const {priceGroup} = task;
  const priceGroupInstance =
    typeof priceGroup === "string" ? priceGroupLookup(priceGroup) : priceGroup;
  const priceGroupIdentifier = priceGroupInstance ? priceGroupInstance.identifier : null;
  let hiddenPriceGroupIdentifierList =
    (priceGroupIdentifier && customerSettings.priceGroupHideTimers[priceGroupIdentifier]) || [];
  const machineUse = task.machineuseSet;
  if (machineUse && machineUse.length) {
    (machineUse as readonly (InlinedTask["machineuseSet"][0] | MachineUse)[]).forEach((m) => {
      const url = m.priceGroup;
      if (url) {
        const machinePriceGroup = typeof url === "string" ? priceGroupLookup(url) : url;
        const identifier = machinePriceGroup ? machinePriceGroup.identifier : undefined;
        if (identifier) {
          const machinePriceGroupHiddenIdentifiers =
            customerSettings.priceGroupHideTimers[identifier];
          if (machinePriceGroupHiddenIdentifiers && machinePriceGroupHiddenIdentifiers.length) {
            hiddenPriceGroupIdentifierList = hiddenPriceGroupIdentifierList.concat(
              machinePriceGroupHiddenIdentifiers,
            );
          }
        }
      }
    });
  }
  if (hiddenPriceGroupIdentifierList.length) {
    const hiddenPriceGroupIdentifierSet = new Set(hiddenPriceGroupIdentifierList);
    return secondaryTimers.filter((timer) => !hiddenPriceGroupIdentifierSet.has(timer.identifier));
  }
  return secondaryTimers;
};

const filterWorkTypeHiddenTimers = (
  secondaryTimers: Timer[],
  task: InlinedTask | Task,
  customerSettings: Config,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
): Timer[] => {
  const workTypeURL = task.workType;
  const workType = typeof workTypeURL === "string" ? workTypeLookup(workTypeURL) : null;
  const workTypeIdentifier = workType ? workType.identifier : null;
  if (workTypeIdentifier) {
    const workTypeHiddenTimerList = customerSettings.workTypeHideTimers[workTypeIdentifier];
    if (workTypeHiddenTimerList) {
      return secondaryTimers.filter((timer) => !workTypeHiddenTimerList.includes(timer.identifier));
    }
  }
  return secondaryTimers;
};

export const getTaskSecondaryTimerList = (
  task: InlinedTask | Task,
  customerSettings: Config,
  {
    machineLookup,
    priceGroupLookup,
    timerArray,
    workTypeLookup,
  }: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    timerArray: readonly Timer[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): Timer[] => {
  const isInternal = !task.order;
  let secondaryTimers;
  if (isInternal) {
    secondaryTimers = Array.from(getInternalSecondaryTimers(timerArray));
  } else {
    secondaryTimers = Array.from(getExternalSecondaryTimers(timerArray));
  }
  const workTypeExtraTimerList = getWorktypeExtraTimerList(task, customerSettings, {
    timerArray,
    workTypeLookup,
  });
  if (workTypeExtraTimerList.length) {
    secondaryTimers = workTypeExtraTimerList.concat(secondaryTimers);
  }
  const machineExtraTimerList = getMachineExtraTimerList(task, customerSettings, {
    machineLookup,
    timerArray,
  });
  if (machineExtraTimerList.length) {
    secondaryTimers = machineExtraTimerList.concat(secondaryTimers);
  }
  const workTypeMachineExtraTimerList = getWorktypeMachineExtraTimerList(task, customerSettings, {
    machineLookup,
    timerArray,
    workTypeLookup,
  });
  if (workTypeMachineExtraTimerList.length) {
    secondaryTimers = workTypeMachineExtraTimerList.concat(secondaryTimers);
  }
  if (!isInternal) {
    const departmentExtraTimerList = getDepartmentExtraTimerList(
      task,
      customerSettings,
      timerArray,
    );
    if (departmentExtraTimerList.length) {
      secondaryTimers = departmentExtraTimerList.concat(secondaryTimers);
    }
  }
  const workTypePriceGroupExtraTimerList = getWorkTypePriceGroupExtraTimerList(
    task,
    customerSettings,
    {priceGroupLookup, timerArray, workTypeLookup},
  );
  if (workTypePriceGroupExtraTimerList.length) {
    secondaryTimers = workTypePriceGroupExtraTimerList.concat(secondaryTimers);
  }
  const machinePriceGroupExtraTimerList = getMachinePriceGroupExtraTimerList(
    task,
    customerSettings,
    {machineLookup, priceGroupLookup, timerArray},
  );
  if (machinePriceGroupExtraTimerList.length) {
    secondaryTimers = machinePriceGroupExtraTimerList.concat(secondaryTimers);
  }

  if (Object.keys(customerSettings.priceGroupHideTimers).length) {
    secondaryTimers = filterPriceGroupHiddenTimers(
      secondaryTimers,
      task,
      customerSettings,
      priceGroupLookup,
    );
  }
  if (Object.keys(customerSettings.workTypeHideTimers).length) {
    secondaryTimers = filterWorkTypeHiddenTimers(
      secondaryTimers,
      task,
      customerSettings,
      workTypeLookup,
    );
  }
  return secondaryTimers.filter((timer) => timer.active);
};

export type WorkTypeChangeBlockedReason = "extraTimer" | "replacedDisabledLog";

export function workTypeChangeBlocked(
  task: Task,
  timerMinutesMap: ReadonlyMap<string, number>,
  customerSettings: Config,
  {
    machineLookup,
    priceGroupLookup,
    reportingSpecificationArray,
    reportingSpecificationLookup,
    timerArray,
    workTypeLookup,
  }: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    reportingSpecificationArray: readonly ReportingSpecification[];
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    timerArray: readonly Timer[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): WorkTypeChangeBlockedReason | null {
  const workTypeExtraTimerList = getWorktypeExtraTimerList(task, customerSettings, {
    timerArray,
    workTypeLookup,
  });
  const workTypeMachineExtraTimerList = getWorktypeMachineExtraTimerList(task, customerSettings, {
    machineLookup,
    timerArray,
    workTypeLookup,
  });
  const workTypePriceGroupExtraTimerList = getWorkTypePriceGroupExtraTimerList(
    task,
    customerSettings,
    {priceGroupLookup, timerArray, workTypeLookup},
  );
  if (
    workTypeExtraTimerList
      .concat(workTypeMachineExtraTimerList, workTypePriceGroupExtraTimerList)
      .some((timer) => timerMinutesMap.get(timer.url))
  ) {
    return "extraTimer";
  }
  if (!logChangeLegal(task) && task.reportingSpecification) {
    const reportingSpecification = reportingSpecificationLookup(task.reportingSpecification);
    if (
      reportingSpecification &&
      !reportingSpecification.active &&
      reportingSpecificationArray.some(
        (other) =>
          other.identifier === reportingSpecification.identifier &&
          other.active &&
          other.url !== reportingSpecification.url,
      )
    ) {
      return "replacedDisabledLog";
    }
  }
  return null;
}

export type MachineRemovalBlockedReason = "extraTimer" | "log";
export const machineRemovalBlocked = (
  task: Task,
  machineURL: MachineUrl,
  timerMinutesMap: ReadonlyMap<TimerUrl, number>,
  customerSettings: Config,
  {
    machineLookup,
    priceGroupLookup,
    reportingSpecificationLookup,
    timerArray,
    workTypeLookup,
  }: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    timerArray: readonly Timer[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): MachineRemovalBlockedReason | null => {
  const logChangeIsLegal = _.isEmpty(task.reportingLog) && allLogLocationsInFieldUses(task);
  if (
    !logChangeIsLegal &&
    willRemovalChangeLogSpecification(
      task,
      priceGroupLookup,
      workTypeLookup,
      machineLookup,
      reportingSpecificationLookup,
      machineURL,
    )
  ) {
    return "log";
  }
  if (!Object.keys(customerSettings.workTypeMachineExtraTimers).length) {
    return null;
  }
  const filteredMachineUseList = task.machineuseSet.filter(
    (machineUse) => machineUse.machine === machineURL,
  );
  const fakeTask = {...task, machineuseSet: filteredMachineUseList};
  const workTypeMachineExtraTimerList = getWorktypeMachineExtraTimerList(
    fakeTask,
    customerSettings,
    {machineLookup, timerArray, workTypeLookup},
  );
  if (
    workTypeMachineExtraTimerList.some((timer) => {
      const timerURL = timer.url;
      return timerMinutesMap.get(timerURL);
    })
  ) {
    return "extraTimer";
  }
  return null;
};

export const getRelevantPriceGroupSet = (
  task: Task,
  {
    timerLookup,
    timerMinutesMap,
  }: {
    timerLookup: (url: TimerUrl) => Timer | undefined;
    timerMinutesMap: ReadonlyMap<TimerUrl, number>;
  },
): Set<PriceGroupUrl> => {
  const priceGroupURLArray = [];
  const taskPriceGroupURL = task.priceGroup;
  if (taskPriceGroupURL) {
    priceGroupURLArray.push(taskPriceGroupURL);
  }
  const machineUseList = task.machineuseSet || [];
  machineUseList.forEach((machineUse) => {
    const machineUsePriceGroupURL = machineUse.priceGroup;
    if (machineUsePriceGroupURL) {
      priceGroupURLArray.push(machineUsePriceGroupURL);
    }
  });
  if (timerMinutesMap) {
    timerMinutesMap.forEach((minutes, timerURL) => {
      if (minutes) {
        const timer = timerLookup(timerURL);
        const timerPriceGroupURL = timer && timer.priceGroup;
        if (timerPriceGroupURL) {
          priceGroupURLArray.push(timerPriceGroupURL);
        }
      }
    });
  }
  return new Set(priceGroupURLArray);
};

export const machineOperatorCanSeeFutureTasksUntil = (customerSettings: Config): string => {
  const now = new Date();
  const hoursDigits = 2;
  const minutesDigits = 2;
  const currentTime = `${padZero(now.getHours(), hoursDigits)}:${padZero(
    now.getMinutes(),
    minutesDigits,
  )}`;
  const showFutureTasksOnceTimeIs = customerSettings.oneDayLessVisibleToMachineOperatorsBefore;
  let numberOfDaysIntoTheFutureToShow = customerSettings.daysIntoTheFutureVisibleToMachineOperator;
  if (currentTime < showFutureTasksOnceTimeIs) {
    numberOfDaysIntoTheFutureToShow = Math.max(numberOfDaysIntoTheFutureToShow - 1, 0);
  }
  const lastVisibleDate = new Date();
  lastVisibleDate.setDate(lastVisibleDate.getDate() + numberOfDaysIntoTheFutureToShow);
  return dateToString(lastVisibleDate);
};

export const computeUpdate = (
  resourceInstanceState: Partial<Task> & ResourceInstance,
  desiredResourceInstanceState: Partial<Task> & ResourceInstance,
): Partial<Writable<Task>> => {
  const update: Partial<Writable<Task>> = {};
  Object.entries(desiredResourceInstanceState).forEach(([key, value]) => {
    const oldValue = resourceInstanceState[key as keyof Task];
    if (!_.isEqual(value, oldValue)) {
      (update as any)[key] = value;
    }
  });
  return update;
};

export const computeDepartment = (
  userProfile: UserProfile | null,
  employeeGroup: EmployeeGroup | null,
  customerSettings: Config,
): string => {
  const {
    addNewAgricultureTaskAsDefault,
    addNewContractorTaskAsDefault,
    addNewContractorTaskAsDefaultGroup,
  } = customerSettings;

  if (
    !addNewContractorTaskAsDefault.length &&
    !addNewContractorTaskAsDefaultGroup.length &&
    !addNewAgricultureTaskAsDefault.length
  ) {
    return "";
  }

  if (userProfile && addNewAgricultureTaskAsDefault.includes(userProfile.alias)) {
    return "L";
  }
  if (userProfile && addNewContractorTaskAsDefault.includes(userProfile.alias)) {
    return "E";
  }
  if (employeeGroup && addNewContractorTaskAsDefaultGroup.includes(employeeGroup.c5_id)) {
    return "E";
  } else {
    return "L";
  }
};

export const checkUserAbsence = (
  userURL: string,
  task: Task,
  orderLookup: ((url: OrderUrl) => Order | undefined) | null,
  daysAbsenceArray: readonly DaysAbsence[],
  hoursAbsenceArray: readonly HoursAbsence[],
  absenceWarningDisabledFor: readonly string[],
): boolean => {
  if (!userURL) {
    return false;
  }
  let {date} = task;
  if (!date && orderLookup) {
    const orderURL = task.order;
    if (orderURL) {
      const order = orderLookup(orderURL);
      if (order) {
        ({date} = order);
      }
    }
  }
  if (!date) {
    return false;
  }
  const notNullDate = date;
  return (
    daysAbsenceArray
      .filter((a) => !absenceWarningDisabledFor.includes(a.absenceType))
      .some(
        (absence) =>
          absence.user === userURL &&
          absence.fromDate <= notNullDate &&
          absence.toDate >= notNullDate,
      ) ||
    hoursAbsenceArray
      .filter((a) => !absenceWarningDisabledFor.includes(a.absenceType))
      .some(
        (absence) =>
          absence.user === userURL && dateToString(new Date(absence.fromTimestamp)) === notNullDate,
      )
  );
};

export const getTaskGenericPrimaryTimerLabel = (
  task: Task,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  customerSettings: Config,
): string => {
  let label;
  const workTypeURL = task.workType;
  const workType = workTypeURL && workTypeLookup(workTypeURL);
  let priceGroupURL = task.priceGroup;
  if (!priceGroupURL) {
    const machineUsePriceGroupURLList = getMachineUsePriceGroupURLList(task);
    if (machineUsePriceGroupURLList.length === 1) {
      priceGroupURL = machineUsePriceGroupURLList[0];
    }
  }
  const priceGroup = priceGroupURL && priceGroupLookup(priceGroupURL);
  if (workType && priceGroup) {
    label = _.get(customerSettings.workTypePriceGroupGenericPrimaryTimerLabel, [
      workType.identifier,
      priceGroup.identifier,
    ]);
  }
  if (!label && workType) {
    label = customerSettings.workTypeGenericPrimaryTimerLabel[workType.identifier];
  }
  if (!label) {
    const internal = !task.order;
    label = internal ? customerSettings.internalTimeLabel : customerSettings.effectiveTimeLabel;
  }
  return label;
};

export const hasMultipleManualDistributionPriceItemUses = (
  task: Task,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): boolean => {
  return (
    Object.values(task.priceItemUses || {}).filter((instance) => {
      const priceItemURL = instance.priceItem;
      const priceItem = priceItemLookup(priceItemURL);
      return priceItem && priceItemIsManualDistributionTime(unitLookup, priceItem);
    }).length > 1
  );
};

export const isLegalToAutoDelete = (task: Readonly<Task>, currentUserURL: string): boolean => {
  if (!task) {
    return false;
  }
  const {created, date, machineOperator, order, priority, ...other} = emptyTask;
  const emptyTaskToCheck: Omit<
    Task,
    "created" | "createdBy" | "date" | "machineOperator" | "order" | "priority" | "url"
  > = other;
  return (
    (!task["machineOperator"] || task["machineOperator"] === currentUserURL) &&
    Object.entries(emptyTaskToCheck).every(
      ([k, v]) => _.isEqual(v, task[k as keyof Task]) || task[k as keyof Task] === undefined,
    )
  );
};

function unInlineTimeCorrection(
  timeCorrection:
    | TimeCorrection
    | {
        fromTimestamp: string;
        timer: Timer | null;
        toTimestamp: string;
      },
): TimeCorrection {
  const {fromTimestamp, timer, toTimestamp} = timeCorrection;
  const timerUrl = typeof timer === "object" && timer !== null ? timer.url : timer;
  return {fromTimestamp, timer: timerUrl, toTimestamp};
}

export const willTaskBeRecorded = (
  task: Pick<
    InlinedTask | Task,
    | "cancelled"
    | "completedAsInternal"
    | "machineOperatorTimeCorrectionSet"
    | "managerTimeCorrectionSet"
    | "url"
  >,
  order: Pick<InlinedOrder | Order, "culture" | "customer" | "routePlan"> | undefined,
  customerSettings: Pick<
    Config,
    "c5Sync" | "recordCultureTasks" | "recordCustomerTasks" | "recordInternalTasks"
  >,
  timerStartArray: readonly Pick<TimerStart, "deviceTimestamp" | "task" | "timer">[],
  breakTimer: TimerUrl | undefined,
): boolean => {
  const {c5Sync, recordCultureTasks, recordCustomerTasks, recordInternalTasks} = customerSettings;
  const isCustomerTask = order && order.customer && !task.completedAsInternal && !task.cancelled;
  const isRouteTask = order && order.routePlan;
  // Note that different semantics for culture tasks wrt.
  // completedAsInternal and cancelled.
  const isCultureTask = order && order.culture;
  const isInternalTask = !isCustomerTask && !isCultureTask;
  if (isInternalTask && recordInternalTasks && c5Sync) {
    // Break-only internal tasks should not be transferred to C5;
    // it'll fail due to no time transferred.
    // (Consider them immediately archivable instead.)
    const taskURL = task.url;
    const timerStarts = _.sortBy(
      timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
    const computeTimestamp = new Date();
    const newComputedIntervals = computeIntervalsTruncated(
      timerStarts,
      computeTimestamp.toISOString(),
    );
    const correctionIntervals = task.machineOperatorTimeCorrectionSet.map(unInlineTimeCorrection);
    const managerCorrectionIntervals = task.managerTimeCorrectionSet.map(unInlineTimeCorrection);
    const intervals = mergeIntervals(
      newComputedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
      computeTimestamp.toISOString(),
    );
    const timerMinutes = computeIntervalSums(intervals, computeTimestamp);
    return (
      (breakTimer && !timerMinutes.get(breakTimer)) ||
      Array.from(timerMinutes).some(([timer, minutes]) => timer !== breakTimer && minutes > 0)
    );
  }
  return (
    (isCustomerTask && recordCustomerTasks) ||
    (isRouteTask && recordCustomerTasks) ||
    (isCultureTask && recordCultureTasks) ||
    (isInternalTask && recordInternalTasks)
  );
};

export const getSmallMachinePriceItemURLs = memoizeOne(
  (
    machineArray: readonly Machine[],
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  ): Set<string> => {
    const priceItemArray: string[] = [];
    machineArray
      .filter((machine) => machine.active && machine.smallMachine)
      .forEach((machine) => {
        machine.pricegroups
          .map((priceGroupURL) => priceGroupLookup(priceGroupURL))
          .filter(notUndefined)
          .forEach((priceGroup) => {
            priceGroup.priceGroupItemSet.forEach((priceGroupItem) => {
              priceItemArray.push(priceGroupItem.priceItem);
            });
          });
      });
    return new Set(priceItemArray);
  },
);

/** @deprecated */
export function legacyGetAutoProductsMapping(
  productUseSet: readonly ProductUse[],
  productLookup: (url: ProductUrl) => Product | undefined,
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined,
  customerSettings: Config,
): {
  autoLinesToLines: Map<number, number[]>;
  linesToAutoLines: Map<number, number[]>;
} {
  const {allowDuplicateProductUses, autoSupplementingProducts} = customerSettings;
  const linesToAutoLines = new Map<number, number[]>();
  const autoLinesToLines = new Map<number, number[]>();
  if (!autoSupplementingProducts) {
    return {autoLinesToLines, linesToAutoLines};
  }
  for (
    let baseProductUseIndex = 0;
    baseProductUseIndex < productUseSet.length;
    baseProductUseIndex += 1
  ) {
    console.assert(!linesToAutoLines.has(baseProductUseIndex));
    if (autoLinesToLines.has(baseProductUseIndex)) {
      // we don't support recursive auto-lines
      continue;
    }
    const productUse = productUseSet[baseProductUseIndex];
    const productURL = productUse.product;
    const product = productLookup(productURL);
    const productGroupURL = product && product.group;
    const productGroup = productGroupURL && productGroupLookup(productGroupURL);
    if (productGroup && productGroup.remoteUrl) {
      const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";
      const productGroupID = productGroup.remoteUrl.startsWith(eConomicProductGroupPrefix)
        ? productGroup.remoteUrl.substring(eConomicProductGroupPrefix.length)
        : productGroup.remoteUrl;
      const autoProductIdentifiers = autoSupplementingProducts[productGroupID];
      if (autoProductIdentifiers && autoProductIdentifiers.length) {
        const remainingAutoProductIdentifiers = new Set(autoProductIdentifiers);
        for (
          let autoProductUseIndex = 0;
          autoProductUseIndex < productUseSet.length;
          autoProductUseIndex += 1
        ) {
          // eslint-disable-next-line max-depth
          if (allowDuplicateProductUses && autoLinesToLines.has(autoProductUseIndex)) {
            // auto-added product use for a *different* entry;
            // and with allowDuplicateProductUses it won't be "shared"
            continue;
          }
          const otherProductUse = productUseSet[autoProductUseIndex];
          const otherProductURL = otherProductUse.product;
          const otherProduct = productLookup(otherProductURL);
          // eslint-disable-next-line max-depth
          if (
            otherProduct &&
            otherProduct.active &&
            remainingAutoProductIdentifiers.has(otherProduct.catalogNumber)
          ) {
            const existingBaseToAuto = linesToAutoLines.get(baseProductUseIndex);
            // eslint-disable-next-line max-depth
            if (existingBaseToAuto) {
              existingBaseToAuto.push(autoProductUseIndex);
            } else {
              linesToAutoLines.set(baseProductUseIndex, [autoProductUseIndex]);
            }
            const existingAutoToBase = autoLinesToLines.get(autoProductUseIndex);
            // eslint-disable-next-line max-depth
            if (existingAutoToBase) {
              existingAutoToBase.push(baseProductUseIndex);
            } else {
              autoLinesToLines.set(autoProductUseIndex, [baseProductUseIndex]);
            }
            remainingAutoProductIdentifiers.delete(otherProduct.catalogNumber);
            // eslint-disable-next-line max-depth
            if (!remainingAutoProductIdentifiers.size) {
              break;
            }
          }
        }
      }
    }
  }
  return {autoLinesToLines, linesToAutoLines};
}

/** @deprecated */
export function addToLegacyProductUseSet(
  productUseSet: readonly ProductUse[],
  urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>,
  productArray: readonly Product[],
  productLookup: (url: ProductUrl) => Product | undefined,
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined,
  customerSettings: Config,
  currentUserURL: UserUrl | null,
): ProductUse[] {
  const allowDuplicates = !!(customerSettings && customerSettings.allowDuplicateProductUses);
  const oldValue = productUseSet || [];
  const urls = typeof urlOrURLs === "string" ? [urlOrURLs] : Array.from(urlOrURLs);
  const currentURLs = new Set(oldValue.map((entry) => entry.product));

  const newEntries: ProductUse[] = [];
  let autoProductsAdded = false;
  for (let i = 0; i < urls.length; i += 1) {
    const url = urls[i];
    if (allowDuplicates || !currentURLs.has(url)) {
      newEntries.push({
        addedBy: currentUserURL,
        correctedCount: null,
        count: null,
        notes: "",
        ours: true,
        product: url,
      });
      currentURLs.add(url);
      if (customerSettings.autoSupplementingProducts) {
        const product = productLookup(url);
        const productGroupURL = product && product.group;
        const productGroup = productGroupURL && productGroupLookup(productGroupURL);
        if (productGroup && productGroup.remoteUrl) {
          const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";
          const productGroupID = productGroup.remoteUrl.startsWith(eConomicProductGroupPrefix)
            ? productGroup.remoteUrl.substring(eConomicProductGroupPrefix.length)
            : productGroup.remoteUrl;
          const autoProductIdentifiers = customerSettings.autoSupplementingProducts[productGroupID];
          // eslint-disable-next-line max-depth
          if (autoProductIdentifiers && autoProductIdentifiers.length) {
            // eslint-disable-next-line max-depth
            for (let j = 0; j < autoProductIdentifiers.length; j += 1) {
              const productID = autoProductIdentifiers[j];
              const supplementingProduct = productArray.find(
                (p) => p.active && p.catalogNumber === productID,
              );
              // eslint-disable-next-line max-depth
              if (supplementingProduct) {
                const productURL = supplementingProduct.url;
                // eslint-disable-next-line max-depth
                if (allowDuplicates || !currentURLs.has(productURL)) {
                  newEntries.push({
                    addedBy: currentUserURL,
                    correctedCount: null,
                    count: null,
                    notes: "",
                    ours: true,
                    product: productURL,
                  });
                  currentURLs.add(productURL);
                  autoProductsAdded = true;
                }
              }
            }
          }
        }
      }
    }
  }
  const unsortedNewValue = oldValue.concat(newEntries);
  const sortedNewValue = sortLegacyProductUseListByCatalogNumber(unsortedNewValue, productLookup);
  if (!customerSettings.autoSupplementingProducts || !allowDuplicates) {
    return sortedNewValue;
  }
  const {autoLinesToLines, linesToAutoLines} = legacyGetAutoProductsMapping(
    sortedNewValue,
    productLookup,
    productGroupLookup,
    customerSettings,
  );
  if (autoProductsAdded) {
    // recompute auto-lines; values on auto-lines should match values on
    // lines adding them in order...
    autoLinesToLines.forEach((sourceLineIndices, autoLineIndex) => {
      // we don't support changing allowDuplicates on the fly;
      // allowDuplicates implies that each source line would have
      // separate auto lines
      console.assert(sourceLineIndices.length === 1);
      const [sourceLineIndex] = sourceLineIndices;
      const autoLine = sortedNewValue[autoLineIndex];
      const sourceLine = sortedNewValue[sourceLineIndex];
      sortedNewValue[autoLineIndex] = {
        ...autoLine,
        count: sourceLine.count,
        notes: customerSettings.autoCopyMaterialNoteToSupplementingProductNote
          ? sourceLine.notes
          : "",
      };
    });
  }
  // allowDuplicates implies that each source line would have
  // separate auto lines, rather than auto lines being shared;
  // this is when we care about order to indicate the association
  const sortedWithAutolinesGrouping = sortedNewValue
    .map((line, index): ProductUse | ProductUse[] | undefined => {
      if (autoLinesToLines.has(index)) {
        console.assert(autoLinesToLines.get(index)?.length === 1);
        return undefined;
      }
      const autoLineIndices = linesToAutoLines.get(index);
      if (autoLineIndices) {
        // keep the order between the auto-lines
        const sortedAutoLineIndices = _.sortBy(autoLineIndices);
        const associatedAutoLines = sortedAutoLineIndices.map(
          (autoLineIndex) => sortedNewValue[autoLineIndex],
        );
        return [line, ...associatedAutoLines];
      } else {
        return line;
      }
    })
    .filter(notUndefined)
    .flat();
  return sortedWithAutolinesGrouping;
}

export function taskChangeCustomerCulture(
  customerUrl: CustomerUrl | null,
  cultureUrl: CultureUrl | null,
  data: {
    contactArray: readonly Contact[];
    create: (instance: ResourceTypeUnion) => void;
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    orderLookup: (url: OrderUrl) => Order | undefined;
    task: Task;
    taskArray: readonly Task[];
    unitLookup: (url: UnitUrl) => Unit | undefined;
    update: (url: string, patch: PatchUnion) => void;
  },
  customerSettings: Config,
  currentUserUrl: UserUrl | null,
): string | null {
  // TODO: consider other *archived* tasks on same order?
  const {contactArray, create, customerLookup, orderLookup, task, taskArray, unitLookup, update} =
    data;
  const orderUrl = task.order;
  const order = orderUrl ? orderLookup(orderUrl) : undefined;
  if (!order) {
    return null;
  }
  const taskList = taskArray.filter((t) => t.order === orderUrl);
  if (taskList.length === 1) {
    changeCustomerCulture(
      order,
      customerUrl,
      cultureUrl,
      {
        contactArray,
        customerLookup,
        taskList,
        unitLookup,
      },
      customerSettings,
      update,
    );
    return urlToId(order.url);
  } else {
    const orderCopyId = uuid();
    const orderCopyUrl = instanceURL("order", orderCopyId);
    const orderCopy: Writable<Order> = {
      ...order,
      address: "",
      billed: false,
      contact: null,
      createdBy: currentUserUrl,
      culture: null,
      customer: null,
      id: orderCopyId,
      project: null,
      referenceNumber: "",
      relatedWorkplace: null,
      remoteUrl: "",
      url: orderCopyUrl,
    };
    delete orderCopy.created;

    create(orderCopy);
    update(task.url, [{member: "order", value: orderCopyUrl}]);
    changeCustomerCulture(
      orderCopy,
      customerUrl,
      cultureUrl,
      {
        contactArray,
        customerLookup,
        taskList: [task],
        unitLookup,
      },
      customerSettings,
      update,
    );
    return orderCopyId;
  }
}
export function taskChangeContact(
  contactUrl: ContactUrl | null,
  data: {
    create: (instance: ResourceTypeUnion) => void;
    orderLookup: (url: OrderUrl) => Order | undefined;
    task: Task;
    taskArray: readonly Task[];
    update: (url: string, patch: PatchUnion) => void;
  },
  currentUserUrl: UserUrl | null,
): string | null {
  // TODO: consider other *archived* tasks on same order?
  const {create, orderLookup, task, taskArray, update} = data;
  const orderUrl = task.order;
  const order = orderUrl ? orderLookup(orderUrl) : undefined;
  if (!order) {
    return null;
  }
  const taskList = taskArray.filter((t) => t.order === orderUrl);
  if (taskList.length === 1) {
    update(order.url, [{member: "contact", value: contactUrl}]);
    return urlToId(order.url);
  } else {
    const orderCopyId = uuid();
    const orderCopyUrl = instanceURL("order", orderCopyId);
    const orderCopy: Writable<Order> = {
      ...order,
      billed: false,
      contact: contactUrl,
      createdBy: currentUserUrl,
      id: orderCopyId,
      remoteUrl: "",
      url: orderCopyUrl,
    };
    delete orderCopy.created;

    create(orderCopy);
    update(task.url, [{member: "order", value: orderCopyUrl}]);

    return orderCopyId;
  }
}

export const getOrderFieldNotes = (order: Order): {[fieldURL: string]: string | undefined} => {
  const fieldExtraNotes: {[fieldURL: string]: string | undefined} = {};
  const orderFieldUseList = order && order.orderfielduseSet;
  if (orderFieldUseList && orderFieldUseList.length) {
    orderFieldUseList.forEach((fieldUse) => {
      const {notes} = fieldUse;
      if (notes) {
        const fieldURL = fieldUse.relatedField;
        fieldExtraNotes[fieldURL] = notes;
      }
    });
  }
  return fieldExtraNotes;
};

export const getFieldNotesPerLocation = (
  logSpecification: ReportingSpecification,
  task: Task,
  order: Order,
  locationLookup: (url: LocationUrl) => Location | undefined,
): Map<string, [string | undefined, string | undefined]> | null => {
  const {fieldsUsedFor} = logSpecification;

  if (!fieldsUsedFor || fieldsUsedFor === "unused") {
    return null;
  }
  const fieldUseSet = task.fielduseSet;

  if (!fieldUseSet || !fieldUseSet.length) {
    return null;
  }
  const locations = task.reportingLocations
    ? sortByOrderMember(
        Object.values(task.reportingLocations).filter((entry) => entry.type === fieldsUsedFor),
      )
    : undefined;
  if (!locations || !locations.length) {
    return null;
  }
  const orderNotes = getOrderFieldNotes(order);
  const fieldNotes = new Map<string, [string | undefined, string | undefined]>();

  fieldUseSet.forEach((fieldUse) => {
    const fieldUrl = fieldUse.relatedField;
    const field = locationLookup(fieldUrl);
    console.assert(field);
    if (!field) {
      return;
    }
    fieldNotes.set(fieldUrl, [fieldUse.notes, orderNotes[fieldUrl]]);
  });

  const fieldNotesPerLocation = new Map<string, [string | undefined, string | undefined]>();

  locations.forEach((logLocation) => {
    const locationUrl = logLocation.location;
    if (locationUrl) {
      const notes = fieldNotes.get(locationUrl);
      if (notes) {
        fieldNotesPerLocation.set(locationUrl, notes);
      }
    }
  });
  return fieldNotesPerLocation;
};

const messages = defineMessages({
  referenceNumber: {
    defaultMessage: "Referencenr.",
    id: "label.reference-number",
  },
});

export const getReferenceNumberLabel = (
  enableTaskReferenceNumber: boolean,
  taskReferenceNumberLabel: string | null,
  enableOrderReferenceNumber: boolean,
  orderReferenceNumberLabel: string | null,
  formatMessage: IntlShape["formatMessage"],
): string => {
  const labelFromSettings =
    (enableTaskReferenceNumber && taskReferenceNumberLabel) ||
    (enableOrderReferenceNumber && orderReferenceNumberLabel);
  return labelFromSettings ? labelFromSettings : formatMessage(messages.referenceNumber);
};

export const createNewRouteTask = (
  routePlanURL: RoutePlanUrl,
  currentUserURL: UserUrl | null,
  currentRole: Role | null,
  create: (instance: ResourceTypeUnion) => void,
  setTaskMachineOperatorToSelf: boolean,
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined,
  defaultTaskEmployee: string | null,
): Partial<Task> & ResourceInstance => {
  const orderID = uuid();
  const orderURL = instanceURL("order", orderID);
  const today = dateToString(new Date());
  const userURL = currentUserURL;
  const role = currentRole;
  const userIsManager = role && role.manager;
  const userIsSeniorMachineOperator = role && role.seniorMachineOperator;
  const newOrder: Order = {
    ...emptyOrder,
    createdBy: userURL,
    durationDays: 1,
    id: orderID,
    routePlan: routePlanURL,
    url: orderURL,
  };
  create(newOrder);
  const taskID = uuid();
  const taskURL = instanceURL("task", taskID);
  const routePlan = routePlanLookup(routePlanURL);
  const workTypeURL = routePlan ? routePlan.workType : null;
  const departmentURL = routePlan ? routePlan.department : null;
  const newTask: Writable<Task> = {
    ...emptyTask,
    created: new Date().toISOString(),
    createdBy: userURL,
    department: departmentURL || "",
    id: taskID,
    order: orderURL,

    priority: Math.pow(2, 28),
    url: taskURL, // for local ordering until set by server
    workType: workTypeURL,
  };
  if (!userIsManager && !userIsSeniorMachineOperator) {
    newTask.date = today;
  }
  if ((!userIsManager && !userIsSeniorMachineOperator) || setTaskMachineOperatorToSelf) {
    newTask.machineOperator = userURL;
  } else {
    newTask.machineOperator = defaultTaskEmployee ? instanceURL("user", defaultTaskEmployee) : null;
  }
  create(newTask);
  return newTask;
};

// Timer price item special case: Don't ever remove here;
// we might be missing context...
export function updateTaskPriceGroupsPriceItemUses(
  task: Task,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  orderLookup: (url: OrderUrl) => Order | undefined,
  timerLookup: (url: TimerUrl) => Timer | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  timerStartArray: readonly TimerStart[],
  customerSettings: Config,
  update: (url: string, patch: PatchUnion) => void,
): void {
  if (
    !task.reportApproved &&
    !task.validatedAndRecorded &&
    !task.recordedInC5 &&
    !task.archivable &&
    task.order
  ) {
    const patch: PatchOperation<Task>[] = [];

    const order = task.order ? orderLookup(task.order) : undefined;

    if (order?.routePlan) {
      return;
    }

    if (task.workType) {
      const currentPriceGroup = task.priceGroup ? priceGroupLookup(task.priceGroup) : null;

      const potentialPriceGroups = workTypePotentialPriceGroups(
        task.workType,
        order?.customer || null,
        workTypeLookup,
        priceGroupLookup,
      );

      const priceGroup = recomputePriceGroup(potentialPriceGroups, currentPriceGroup || null);
      const priceGroupURL = priceGroup?.url || null;
      if (priceGroupURL !== task.priceGroup) {
        patch.push({member: "priceGroup", value: priceGroupURL});
      }
    }

    if (task.machineuseSet && task.machineuseSet.length) {
      const newMachineUses = task.machineuseSet.map((machineUse) => {
        const potentialPriceGroups = machinePotentialPriceGroups(
          machineUse.machine,
          order?.customer || null,
          machineLookup,
          priceGroupLookup,
        );
        const currentPriceGroup = machineUse.priceGroup
          ? priceGroupLookup(machineUse.priceGroup)
          : null;
        const priceGroup = recomputePriceGroup(potentialPriceGroups, currentPriceGroup || null);
        const priceGroupURL = priceGroup?.url || null;
        if (priceGroupURL === machineUse.priceGroup) {
          return machineUse;
        }
        return {...machineUse, priceGroup: priceGroupURL};
      });
      if (!_.isEqual(newMachineUses, task.machineuseSet)) {
        patch.push({member: "machineuseSet", value: newMachineUses});
      }
    }

    const taskURL = task.url;
    const taskTimerStartArray = timerStartArray.filter((timerStart) => timerStart.task === taskURL);

    const timersWithTime = getTaskTimersWithTime(applyPatch(task, patch), taskTimerStartArray);

    const changedTask = applyPatch(task, patch) as Task & {
      readonly priceItemUses: PriceItemUsesDict;
    };
    // don't allow remove of price items for any timers
    const noTimers = new Set<string>();
    const priceItemUses = recomputePriceItemUses(
      changedTask,
      timersWithTime,
      timerLookup,
      priceGroupLookup,
      priceItemLookup,
      unitLookup,
      customerSettings,
      noTimers,
    );
    if (priceItemUses !== changedTask.priceItemUses) {
      const priceItemUsesPatch = patchFromPriceItemUsesChange(
        changedTask.priceItemUses,
        priceItemUses,
      );
      patch.push(...priceItemUsesPatch);
    }

    if (patch.length) {
      console.assert(!_.isEqual(task, applyPatch(task, patch)), "non-empty no-op patch");
      update(task.url, patch);
    }
  }
}

export function updateTaskReportingSpecification(
  task: Task,
  data: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    orderLookup: (url: OrderUrl) => Order | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    sprayLogArray: readonly SprayLog[];
    transportLogArray: readonly TransportLog[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
    yieldLogArray: readonly YieldLog[];
  },
  update: (url: string, patch: PatchUnion) => void,
): void {
  const {
    machineLookup,
    orderLookup,
    priceGroupLookup,
    reportingSpecificationLookup,
    sprayLogArray,
    transportLogArray,
    workTypeLookup,
    yieldLogArray,
  } = data;
  if (
    !task.completed &&
    !task.reportApproved &&
    !task.validatedAndRecorded &&
    !task.recordedInC5 &&
    !task.archivable &&
    task.order &&
    (!task.reportingSpecification ||
      (_.isEmpty(task.reportingLog) && _.isEmpty(task.reportingLocations) && !task.logSkipped))
  ) {
    const order = task.order ? orderLookup(task.order) : undefined;

    if (order?.routePlan) {
      return;
    }

    const reportingSpecification = getExpectedLogSpecification(task, {
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      workTypeLookup,
    });

    if (task.reportingSpecification === (reportingSpecification?.url || null)) {
      return;
    }

    const taskUrl = task.url;
    if (
      transportLogArray.some((transportLog) => transportLog.task === taskUrl) ||
      sprayLogArray.some((sprayLog) => sprayLog.task === taskUrl) ||
      yieldLogArray.some((yieldLog) => yieldLog.task === taskUrl)
    ) {
      return;
    }

    update(task.url, [
      {
        member: "reportingSpecification",
        value: reportingSpecification?.url || null,
      },
    ]);
  }
}

export function computeTime(taskList: readonly Task[]): string | undefined {
  return taskList.map((t) => t.time).find(Boolean) || undefined;
}

export function buildConversionRelatedValues(
  conversionRelatedKeys: readonly string[],
  priceItemUsesWithData: readonly {
    readonly priceItemUse: PriceItemUseWithOrder;
    readonly unit: Unit | null;
  }[],
  extraConversionRelatedValues?: {readonly [unit: string]: number | null},
): {readonly [unit: string]: number | null} | null {
  if (!conversionRelatedKeys.length) {
    return null;
  }
  const extraConversionRelatedValuesLowerCase = extraConversionRelatedValues
    ? Object.fromEntries(
        Object.entries(extraConversionRelatedValues).map(([unitString, value]) => [
          unitString.toLocaleLowerCase(),
          value,
        ]),
      )
    : undefined;
  const result: {[unit: string]: number | null} = {};
  conversionRelatedKeys.forEach((unitString) => {
    if (extraConversionRelatedValues && extraConversionRelatedValues[unitString] !== undefined) {
      result[unitString] = extraConversionRelatedValues[unitString];
    } else if (
      extraConversionRelatedValuesLowerCase &&
      extraConversionRelatedValuesLowerCase[unitString.toLocaleLowerCase()] !== undefined
    ) {
      result[unitString] = extraConversionRelatedValuesLowerCase[unitString.toLocaleLowerCase()];
    } else {
      for (const {priceItemUse, unit} of priceItemUsesWithData) {
        if (unit?.name === unitString) {
          result[unitString] = priceItemUse.count;
          break;
        }
      }
    }
  });
  if (Object.keys(result).length) {
    return result;
  } else {
    return null;
  }
}
