import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Project,
  ProjectUrl,
  ResourceTypeUnion,
  RoutePlan,
  RoutePlanTaskActivityOption,
  RoutePlanTask as RoutePlanTaskResource,
  RoutePlanTaskResult,
  RoutePlanUrl,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {
  DeleteDialog,
  ErrorColorButton,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  ConnectedLocationDialog,
  ConnectedProjectDialog,
} from "@co-frontend-libs/connected-components";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentUserSelectableProjectArray,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProjectLookup,
  getRoutePlanLookup,
  getRoutePlanTaskActivityOptionArray,
  getRoutePlanTaskLookup,
  getRoutePlanTaskResultArray,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Button, Card, CardContent, CardHeader, CardMedia, Chip} from "@material-ui/core";
import {DoLoadInstance, PageLayout} from "app-components";
import {LoadInstanceRelated, PureComponent, removeUnicodeCf} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {RoutePlanTaskActivitySelection} from "./route-plan-task-activity-selection";

interface RoutePlanTaskContentProps {
  back: (count?: number, fallback?: PathTemplate) => void;
  create: (instance: ResourceTypeUnion) => void;
  currentUserSelectableProjectArray: readonly Project[];
  customer?: Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  project?: Project | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  remove: (url: string) => void;
  routePlan?: RoutePlan | undefined;
  routePlanTask: RoutePlanTaskResource;
  routePlanTaskActivityOptionSet: ReadonlySet<RoutePlanTaskActivityOption>;
  routePlanTaskResultSet: ReadonlySet<RoutePlanTaskResult>;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
  workplace?: Location | undefined;
  workType?: WorkType | undefined;
}

interface RoutePlanTaskContentState {
  deleteDialogOpen: boolean;
  projectDialogOpen: boolean;
  workplaceDialogOpen: boolean;
}

class RoutePlanTaskContent extends PureComponent<
  RoutePlanTaskContentProps,
  RoutePlanTaskContentState
> {
  state = {
    deleteDialogOpen: false,
    projectDialogOpen: false,
    workplaceDialogOpen: false,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleDescriptionChange(value: string): void {
    const {routePlanTask, update} = this.props;
    update(routePlanTask.url, [{member: "description", value}]);
  }
  @bind
  handleNotesFromManagerChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {routePlanTask, update} = this.props;
    update(routePlanTask.url, [{member: "notesFromManager", value: filteredValue}]);
  }
  @bind
  handleDeadlineChange(value: string | null): void {
    const {routePlanTask, update} = this.props;
    update(routePlanTask.url, [{member: "deadline", value}]);
  }
  @bind
  handleDeleteClick(_event: unknown): void {
    this.setState({deleteDialogOpen: true});
  }
  @bind
  handleDeleteDialogOk(): void {
    this.setState({deleteDialogOpen: false});
    const {back, remove, routePlanTask, routePlanTaskActivityOptionSet, routePlanTaskResultSet} =
      this.props;
    routePlanTaskResultSet.forEach((routePlanTaskResult) => {
      remove(routePlanTaskResult.url);
    });
    routePlanTaskActivityOptionSet.forEach((routePlanTaskActivityOption) => {
      remove(routePlanTaskActivityOption.url);
    });
    remove(routePlanTask.url);
    back();
  }
  @bind
  handleDeleteDialogCancel(): void {
    this.setState({deleteDialogOpen: false});
  }
  @bind
  handleWorkplaceButtonClick(): void {
    this.setState({workplaceDialogOpen: true});
  }
  @bind
  handleWorkplaceDialogCancel(): void {
    this.setState({workplaceDialogOpen: false});
  }
  @bind
  handleWorkplaceDialogOk(url: LocationUrl): void {
    this.setState({workplaceDialogOpen: false});
    const {locationLookup, routePlanTask, update} = this.props;
    const workplace = locationLookup(url);
    const description = workplace ? workplace.name || formatAddress(workplace) : null;
    if (description) {
      update(routePlanTask.url, [
        {member: "description", value: description},
        {member: "relatedLocation", value: url},
      ]);
    } else {
      update(routePlanTask.url, [{member: "location", value: url}]);
    }
  }
  @bind
  handleProjectSelectButton(): void {
    this.setState({projectDialogOpen: true});
  }
  @bind
  handleProjectDialogCancel(): void {
    this.setState({projectDialogOpen: false});
  }
  @bind
  handleProjectDialogOk(url: ProjectUrl): void {
    this.setState({projectDialogOpen: false});
    const {routePlanTask, update} = this.props;
    update(routePlanTask.url, [{member: "project", value: url}]);
  }
  @bind
  handleRemoveProject(): void {
    const {routePlanTask, update} = this.props;
    update(routePlanTask.url, [{member: "project", value: null}]);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      create,
      currentUserSelectableProjectArray,
      customer,
      customerSettings,
      priceGroupLookup,
      priceItemLookup,
      projectLookup,
      remove,
      routePlan,
      routePlanTask,
      routePlanTaskActivityOptionSet,
      routePlanTaskResultSet,
      unitLookup,
      update,
      workplace,
      workType,
    } = this.props;
    const {deleteDialogOpen, projectDialogOpen, workplaceDialogOpen} = this.state;
    const customerName = customer ? customer.name : "";
    const customerPhone = customer ? customer.phone : "";
    const customerEmail = customer ? customer.billingEmail : "";
    const {description} = routePlanTask;
    const {notesFromManager} = routePlanTask;
    const {deadline} = routePlanTask;
    const customerURL = customer ? customer.url : null;
    let projectBlock;
    if (customerSettings.enableProjects) {
      const departments = this.props.customerSettings.onlyEnableProjectsForDepartments;
      const enabledForDepartment =
        !departments.length || (routePlan && departments.includes(routePlan.department));
      let noProjects = true;
      if (customerURL) {
        noProjects = !currentUserSelectableProjectArray.some(
          (project) => project.customer === customerURL,
        );
      } else {
        noProjects = !currentUserSelectableProjectArray.length;
      }
      let currentProject;
      const projectURL = routePlanTask.project;
      if (projectURL) {
        const project = projectLookup(projectURL);
        if (project) {
          currentProject = (
            <Chip
              label={`${project.projectNumber}: ${project.name}`}
              onDelete={this.handleRemoveProject}
            />
          );
        }
      }
      projectBlock = (
        <Cell>
          {customerSettings.projectLabelVariant === "PROJECT" ? (
            <FormattedMessage defaultMessage="Projekt" tagName="h4" />
          ) : (
            <FormattedMessage defaultMessage="Sag" tagName="h4" />
          )}
          <Button
            color="secondary"
            disabled={noProjects || !enabledForDepartment}
            variant="contained"
            onClick={this.handleProjectSelectButton}
          >
            {customerSettings.projectLabelVariant === "PROJECT"
              ? formatMessage({defaultMessage: "Vælg projekt"})
              : formatMessage({defaultMessage: "Vælg sag"})}
          </Button>
          {currentProject}
        </Cell>
      );
    }

    const dialogs = [
      <DeleteDialog
        key="delete-dialog"
        open={deleteDialogOpen}
        onCancel={this.handleDeleteDialogCancel}
        onOk={this.handleDeleteDialogOk}
      >
        <FormattedMessage defaultMessage="Slet rutepunkt?" />
      </DeleteDialog>,
      <ConnectedLocationDialog
        key="workplace-dialog"
        includeWorkplaceOnlyLocations
        customerURL={customerURL}
        includeLogOnlyLocations={false}
        open={workplaceDialogOpen}
        titleVariant="WORKPLACE"
        onCancel={this.handleWorkplaceDialogCancel}
        onOk={this.handleWorkplaceDialogOk}
      />,
      <ConnectedProjectDialog
        key="project-dialog"
        customerURL={customerURL || undefined}
        open={projectDialogOpen}
        suggestRecentlyUsed={false}
        onCancel={this.handleProjectDialogCancel}
        onOk={this.handleProjectDialogOk}
      />,
    ];
    return (
      <PageLayout dialogs={dialogs} toolbar={formatMessage({defaultMessage: "Rediger rutepunkt"})}>
        <Card style={{margin: "1em"}}>
          <CardContent>
            <Grid>
              <Cell palm="12/12">
                <FormattedMessage defaultMessage="Kunde" tagName="h4" />
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Navn:" />
                      </td>
                      <td>{customerName}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Adresse:" />
                      </td>
                      <td>{formatAddress(customer)}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Tlf.:" />
                      </td>
                      <td>{customerPhone}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="E-mail:" />
                      </td>
                      <td>{customerEmail}</td>
                    </tr>
                  </tbody>
                </table>
              </Cell>
              {projectBlock}
              <Cell>
                <FormattedMessage defaultMessage="Sted" tagName="h4" />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleWorkplaceButtonClick}
                >
                  {formatMessage({defaultMessage: "Vælg arbejdssted"})}
                </Button>
                <div>
                  {workplace ? workplace.name : null}
                  <br />
                  {formatAddress(workplace)}
                </div>
              </Cell>
            </Grid>
          </CardContent>
        </Card>
        <Card style={{margin: "1em"}}>
          <CardContent>
            <div>
              <TrimTextField
                fullWidth
                label={formatMessage({defaultMessage: "Beskrivelse"})}
                margin="dense"
                value={description}
                variant="outlined"
                onChange={this.handleDescriptionChange}
              />
            </div>
            <div>
              <TrimTextField
                fullWidth
                label={formatMessage({defaultMessage: "Noter"})}
                margin="dense"
                value={notesFromManager}
                variant="outlined"
                onChange={this.handleNotesFromManagerChange}
              />
            </div>
            <div>
              <TimeField
                label={formatMessage({defaultMessage: "Skal udføres inden kl."})}
                margin="dense"
                value={deadline || undefined}
                onChange={this.handleDeadlineChange}
              />
            </div>
          </CardContent>
        </Card>
        <Card style={{margin: "1em"}}>
          <CardHeader title={formatMessage({defaultMessage: "Kan udføres som:"})} />
          <CardMedia>
            <RoutePlanTaskActivitySelection
              create={create}
              customer={customer}
              customerSettings={this.props.customerSettings}
              priceGroupLookup={priceGroupLookup}
              priceItemLookup={priceItemLookup}
              remove={remove}
              routePlanTask={routePlanTask}
              routePlanTaskActivityOptionSet={routePlanTaskActivityOptionSet}
              routePlanTaskResultSet={routePlanTaskResultSet}
              unitLookup={unitLookup}
              update={update}
              workType={workType}
            />
          </CardMedia>
        </Card>
        <div style={{margin: "1em"}}>
          <ErrorColorButton
            style={{width: "100%"}}
            variant="contained"
            onClick={this.handleDeleteClick}
          >
            {formatMessage({defaultMessage: "Slet"})}
          </ErrorColorButton>
        </div>
      </PageLayout>
    );
  }
}

interface RoutePlanTaskContainerStateProps {
  currentUserSelectableProjectArray: readonly Project[];
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  routePlanTaskActivityOptionArray: readonly RoutePlanTaskActivityOption[];
  routePlanTaskResultArray: readonly RoutePlanTaskResult[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface RoutePlanTaskContainerDispatchProps {
  back: (count?: number, fallback?: PathTemplate) => void;
  create: (instance: ResourceTypeUnion) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface RoutePlanTaskContainerOwnProps {
  instance: RoutePlanTaskResource;
}

type RoutePlanTaskContainerProps = RoutePlanTaskContainerDispatchProps &
  RoutePlanTaskContainerOwnProps &
  RoutePlanTaskContainerStateProps;

class RoutePlanTaskContainer extends PureComponent<RoutePlanTaskContainerProps> {
  render(): JSX.Element {
    const {
      back,
      create,
      currentUserSelectableProjectArray,
      customerLookup,
      locationLookup,
      priceGroupLookup,
      priceItemLookup,
      projectLookup,
      remove,
      routePlanLookup,
      routePlanTaskActivityOptionArray,
      routePlanTaskResultArray,
      unitLookup,
      update,
      workTypeLookup,
    } = this.props;
    const routePlanTask = this.props.instance;
    const routePlanTaskURL = routePlanTask.url;
    const routePlanURL = routePlanTask.routePlan;
    const routePlan = routePlanLookup(routePlanURL);
    const workTypeURL = routePlan ? routePlan.workType : null;
    const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
    const customerURL = routePlanTask.customer;
    const customer = customerLookup(customerURL);
    const workplaceURL = routePlanTask.relatedLocation;
    const workplace = locationLookup(workplaceURL);
    const projectURL = routePlanTask.project;
    const project = projectURL ? projectLookup(projectURL) : undefined;
    const routePlanTaskActivityOptionSet = new Set(
      routePlanTaskActivityOptionArray.filter(
        (routePlanTaskActivityOption) =>
          routePlanTaskActivityOption.routePlanTask === routePlanTaskURL,
      ),
    );
    const routePlanTaskResultSet = new Set(
      routePlanTaskResultArray.filter(
        (routePlanTaskResult) => routePlanTaskResult.routePlanTask === routePlanTaskURL,
      ),
    );
    return (
      <RoutePlanTaskContent
        back={back}
        create={create}
        currentUserSelectableProjectArray={currentUserSelectableProjectArray}
        customer={customer}
        customerSettings={this.props.customerSettings}
        locationLookup={locationLookup}
        priceGroupLookup={priceGroupLookup}
        priceItemLookup={priceItemLookup}
        project={project}
        projectLookup={projectLookup}
        remove={remove}
        routePlan={routePlan}
        routePlanTask={routePlanTask}
        routePlanTaskActivityOptionSet={routePlanTaskActivityOptionSet}
        routePlanTaskResultSet={routePlanTaskResultSet}
        unitLookup={unitLookup}
        update={update}
        workplace={workplace}
        workType={workType}
      />
    );
  }
}

const ConnectedRoutePlanTaskContainer = connect<
  RoutePlanTaskContainerStateProps,
  RoutePlanTaskContainerDispatchProps,
  RoutePlanTaskContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, RoutePlanTaskContainerStateProps>({
    currentUserSelectableProjectArray: getCurrentUserSelectableProjectArray,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    projectLookup: getProjectLookup,
    routePlanLookup: getRoutePlanLookup,
    routePlanTaskActivityOptionArray: getRoutePlanTaskActivityOptionArray,
    routePlanTaskResultArray: getRoutePlanTaskResultArray,
    unitLookup: getUnitLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    back: actions.back,
    create: actions.create,
    remove: actions.remove,
    update: actions.update,
  },
)(RoutePlanTaskContainer);

const related: LoadInstanceRelated = [
  {
    memberName: "routePlanTask",
    resourceType: "routePlanTaskActivityOption",
    type: "hasForeignKey",
  },
  {
    memberName: "routePlanTask",
    resourceType: "routePlanTaskResult",
    type: "hasForeignKey",
  },
  {
    memberName: "routePlan",
    resourceType: "routePlan",
    type: "targetOfForeignKey",
  },
] as const;

class LoadRoutePlanTask extends React.Component<undefined> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage} = this.context;
    return (
      <DoLoadInstance
        Component={ConnectedRoutePlanTaskContainer}
        loadingTitle={formatMessage({defaultMessage: "Rediger rutepunkt"})}
        lookupSelector={getRoutePlanTaskLookup}
        related={related}
        resourceName="routePlanTask"
      />
    );
  }
}

export {LoadRoutePlanTask as RoutePlanTask};
