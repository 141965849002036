import {Config} from "@co-common-libs/config";
import {
  ComputedTime,
  Contact,
  Customer,
  CustomerUrl,
  DaysAbsence,
  Delivery,
  DeliveryLocation,
  DeliveryLocationUrl,
  HoursAbsence,
  Location,
  LocationUrl,
  LocationUseLog,
  Machine,
  MachineGroup,
  MachineGroupUrl,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PatchUnion,
  Pickup,
  PickupLocation,
  PickupLocationUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUsesDict,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUseLog,
  ProductUsesDict,
  Project,
  ProjectUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  ResourceTypeUnion,
  Role,
  SprayLocation,
  SprayLog,
  Task,
  Timer,
  TimerStart,
  TimerUrl,
  TransportLog,
  Unit,
  UnitUrl,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  YieldDeliveryLocation,
  YieldLog,
  YieldPickupLocation,
  emptyTransportLog,
  urlToId,
} from "@co-common-libs/resources";
import {
  addToProductUses,
  allowWorkplaceCreate,
  getNormalisedDeviceTimestamp,
  getReadonlyPriceItems,
  getUpdatedFieldUseList,
  getWorkTypeString,
  workTypePotentialPriceGroups,
} from "@co-common-libs/resources-utils";
import {
  dateToString,
  formatDate,
  notNull,
  notUndefined,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {
  DateField,
  DeleteDialog,
  ErrorColorButton,
  MinutesField,
  ThrottledTextField,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  ConnectedDepartmentDialog,
  ConnectedLogLegalExternalWorkTypeDialog,
  ConnectedLogLegalPriceGroupDialog,
  ConnectedMachineOperatorDialog,
  ConnectedProductDialog,
} from "@co-frontend-libs/connected-components";
import {
  AppState,
  PathTemplate,
  actions,
  getContactArray,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getDaysAbsenceArray,
  getDeliveryArray,
  getDeliveryLocationArray,
  getDeliveryLocationLookup,
  getHoursAbsenceArray,
  getLocationArray,
  getLocationLookup,
  getLocationUseLogArray,
  getMachineGroupLookup,
  getMachineLookup,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getPickupLocationLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductArray,
  getProductGroupLookup,
  getProductLookup,
  getProductUseLogArray,
  getProjectLookup,
  getReportingSpecificationArray,
  getReportingSpecificationLookup,
  getSprayLocationArray,
  getSprayLogArray,
  getTaskArray,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getTransportLogArray,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  getYieldDeliveryLocationArray,
  getYieldLogArray,
  getYieldPickupLocationArray,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  ChipProps,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  CustomerSelectCreateDialog,
  DeliveryLocationDialog,
  DoLoadInstance,
  FieldMultiSelectionDialog,
  FieldUseWithField,
  FieldsCard,
  LocationSelectCreateDialog,
  MachinePriceGroupWizard,
  MachineRemovalBlockedDialog,
  PageLayout,
  PickupLocationDialog,
  ProductGroupTreeDialog,
  ProjectBlock,
  SelectTaskFields,
  TaskCountMessage,
  TaskPriceItemTable,
  TaskProductTable,
  TimeDistributionTable,
  TransportLogDialog,
  WorkTypeChangeBlockedDialog,
  getOtherTaskCount,
  getUsedFieldUrlSet,
} from "app-components";
import {
  InlinedMachineUse,
  MachineRemovalBlockedReason,
  PureComponent,
  WorkTypeChangeBlockedReason,
  changeCustomerCulture,
  checkUserAbsence,
  computeIntervalSums,
  computeIntervalsTruncated,
  computePatch,
  copyTaskWithLogsLocations,
  focusButton,
  getDepartmentName,
  getGenericEffectiveTimer,
  getRelevantPriceGroupSet,
  hasMultipleManualDistributionPriceItemUses,
  inlineMachineUse,
  machineAlreadySelected,
  machineRemovalBlocked,
  mergeIntervals,
  readProductUseLog,
  removeUnicodeCf,
  updateTaskPriceGroupsPriceItemUses,
  workTypeChangeBlocked,
} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages, useIntl} from "react-intl";
import {connect} from "react-redux";
import {Dispatch, bindActionCreators} from "redux";
import {createStructuredSelector} from "reselect";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {LogPlaces} from "../order-instance/log-places";
import {allowMachineUseForWorktype} from "../order-instance/task-instance";
import TransportLogBlock from "../order-instance/transport-log-block";
import MachineBlock from "./machine-block";

const messages = defineMessages({
  addProductButton: {
    defaultMessage: "Tilføj materiel",
    id: "order-instance.label.add-product",
  },
  address: {
    defaultMessage: "Arbejdssted",
    id: "order-instance.label.task-address",
  },
  addTransportLog: {
    defaultMessage: "Tilføj transportlog",
    id: "task-instance.label.add-transport-log",
  },
  alternativeAddProductButton: {
    defaultMessage: "Tilføj materialer",
    id: "order-instance.alternative-label.add-product",
  },
  arrivalAtLocation: {
    defaultMessage: "Start ved kunden kl.",
    id: "task-instance.label.arrival-at-customer",
  },
  date: {
    defaultMessage: "Dato",
  },
  deleteTask: {
    defaultMessage: "Slet opgave",
  },
  expectedTotalTaskDuration: {
    defaultMessage: "Forventet varighed",
    id: "task-instance.label.expectedTotalTaskDuration",
  },
  invoiceNote: {
    defaultMessage: "Faktura note",
    id: "order-instance.label.invoice-note",
  },
  noMachineGroup: {
    defaultMessage: "Ingen maskingruppe",
    id: "order-entry.label.no-machine-group",
  },
  notesFromAdministration: {
    defaultMessage: "Noter fra administration",
  },
  referenceNumber: {
    defaultMessage: "Referencenummer",
    id: "task-details.label.reference-number",
  },
  selectDepartmentButton: {
    defaultMessage: "Vælg",
    id: "order-instance.label.select-department",
  },
  selectMachineOperatorButton: {
    defaultMessage: "Vælg",
  },
  selectPriceGroupButton: {
    defaultMessage: "Vælg variant",
    id: "task-details.label.select-price-group",
  },
  selectWorkplaceButton: {
    defaultMessage: "Vælg arbejdssted",
    id: "order-instance.label.select-workplace",
  },
  selectWorkTypeButton: {
    defaultMessage: "Vælg område",
    id: "task-details.label.select-work-type",
  },
  taskInternalManagerNotes: {
    defaultMessage: "Interne noter",
  },
  taskTitle: {
    defaultMessage: "Opgave",
    id: "task-details.title.task",
  },
  time: {
    defaultMessage: "Klokkeslæt",
  },
});

interface TaskDetailsProps {
  computedIntervals: readonly ComputedTime[];
  contactArray: readonly Contact[];
  create: (instance: ResourceTypeUnion) => void;
  createOrUpdate: (instance: ResourceTypeUnion) => void;
  currentRole: Role | null;
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  deliveryLocationLookup: (url: DeliveryLocationUrl) => DeliveryLocation | undefined;
  dispatch: Dispatch;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  hoursAbsenceArray: readonly HoursAbsence[];
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: string | null;
    readonly toTimestamp: string;
  }[];
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  locationUseLogArray: readonly LocationUseLog[];
  machineGroupLookup: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
  pickupLocationLookup: (url: PickupLocationUrl) => PickupLocation | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productArray: readonly Product[];
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  productUseLogArray: readonly ProductUseLog[];
  projectLookup: (url: ProjectUrl) => Project | undefined;
  remove: (url: string) => void;
  reportingSpecificationArray: readonly ReportingSpecification[];
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  sprayLocationArray: readonly SprayLocation[];
  sprayLogArray: readonly SprayLog[];
  task: Task;
  taskArray: readonly Task[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
  timerStartArray: readonly TimerStart[];
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

interface TaskDetailsState {
  customerDialogCallback: ((customer: Customer) => void) | null;
  deleteDialogOpen: boolean;
  deliveryLocationDialog: {
    amount: number | null;
    areaHa: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    relatedUnit: Unit | null;
    unit: string;
    url: DeliveryLocationUrl | null;
  };
  departmentDialogOpen: boolean;
  editTransportLogDialogTask: Task | null;
  editTransportLogDialogTransportLog: TransportLog | null;
  fieldDialogOpen: boolean;
  machineDialogOpen: boolean;
  machineOperatorDialogOpen: boolean;
  machineRemovalBlockedDialogOpen: boolean;
  machineRemovalBlockedReason: MachineRemovalBlockedReason | null;
  pickupLocationDialog: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    relatedUnit: Unit | null;
    unit: string;
    url: PickupLocationUrl | null;
  };
  priceGroupDialogOpen: boolean;
  productDialogOpen: boolean;
  workplaceDialogOpen: boolean;
  workTypeChangeBlockedDialogOpen: boolean;
  workTypeChangeBlockedReason: WorkTypeChangeBlockedReason | null;
  workTypeDialogOpen: boolean;
}

class TaskDetails extends React.Component<TaskDetailsProps, TaskDetailsState> {
  state: TaskDetailsState = {
    customerDialogCallback: null,
    deleteDialogOpen: false,
    deliveryLocationDialog: {
      amount: null,
      areaHa: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
    },
    departmentDialogOpen: false,
    editTransportLogDialogTask: null,
    editTransportLogDialogTransportLog: null,
    fieldDialogOpen: false,
    machineDialogOpen: false,
    machineOperatorDialogOpen: false,
    machineRemovalBlockedDialogOpen: false,
    machineRemovalBlockedReason: null,
    pickupLocationDialog: {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
    },
    priceGroupDialogOpen: false,
    productDialogOpen: false,
    workplaceDialogOpen: false,
    workTypeChangeBlockedDialogOpen: false,
    workTypeChangeBlockedReason: null,
    workTypeDialogOpen: false,
  };
  componentDidMount(): void {
    if (this.machineBlock.current) {
      focusButton(this.machineBlock.current.selectButton);
    }
    const {
      customerSettings,
      machineLookup,
      orderLookup,
      priceGroupLookup,
      priceItemLookup,
      task,
      timerLookup,
      timerStartArray,
      unitLookup,
      update,
      workTypeLookup,
    } = this.props;
    updateTaskPriceGroupsPriceItemUses(
      task,
      workTypeLookup,
      machineLookup,
      priceGroupLookup,
      priceItemLookup,
      orderLookup,
      timerLookup,
      unitLookup,
      timerStartArray,
      customerSettings,
      update,
    );
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  machineBlock = React.createRef<MachineBlock>();
  selectMachineOperatorButton = React.createRef<HTMLButtonElement>();
  selectWorkTypeButton = React.createRef<HTMLButtonElement>();
  selectPriceGroupButton = React.createRef<HTMLButtonElement>();
  @bind
  handleDateChange(newValue: string | null): void {
    const {dispatch, task, taskArray} = this.props;
    const orderURL = task.order as string;
    console.assert(orderURL);
    dispatch(actions.updateDiff({date: newValue}, task));
    if (taskArray.filter((t) => t.order === orderURL).length === 1) {
      this.props.update(orderURL, [{member: "date", value: newValue}]);
    }
  }
  @bind
  handleDeleteButton(): void {
    this.setState({deleteDialogOpen: true});
  }
  @bind
  handleDeleteDialogOk(): void {
    if (!this.state.deleteDialogOpen) {
      return;
    }
    this.setState({deleteDialogOpen: false});
    const {task} = this.props;
    const taskURL = task.url;
    const orderURL = task.order as string;
    console.assert(orderURL);
    const orderID = urlToId(orderURL);
    this.props.remove(taskURL);
    window.setTimeout(() => {
      this.props.go("/orderEntry/:id", {id: orderID});
    });
  }
  @bind
  handleDeleteDialogCancel(): void {
    this.setState({deleteDialogOpen: false});
  }
  @bind
  handleMachineSelectButton(): void {
    this.setState({machineDialogOpen: true});
  }
  @bind
  handleMachineDialogOk(machineURL: MachineUrl, priceGroupURL: PriceGroupUrl | null): void {
    this.setState({machineDialogOpen: false});
    const {dispatch, task} = this.props;

    const oldMachines = task.machineuseSet || [];
    if (machineAlreadySelected(task, machineURL)) {
      return;
    }
    const newEntry: MachineUse = {
      machine: machineURL,
      priceGroup: priceGroupURL,
      transporter: false,
    };
    const newMachines = [...oldMachines, newEntry];
    dispatch(actions.updateDiff({machineuseSet: newMachines}, task));
    const workType = task.workType ? this.props.workTypeLookup(task.workType) : undefined;
    if (workType?.department) {
      return;
    }
    const machine = this.props.machineLookup(machineURL);
    if (!machine?.department) {
      return;
    }
    const oldMachineDepartments = new Set(
      oldMachines
        .map((machineUse) => this.props.machineLookup(machineUse.machine))
        .filter(notUndefined)
        .map((oldMachine) => oldMachine.department)
        .filter(Boolean),
    );
    if (oldMachineDepartments.size && !oldMachineDepartments.has(machine.department)) {
      this.setState({departmentDialogOpen: true});
    }
  }
  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
    if (this.machineBlock.current) {
      focusButton(this.machineBlock.current.selectButton);
    }
  }
  @bind
  handlePriceGroupSelectButton(): void {
    const {
      customerSettings,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationArray,
      reportingSpecificationLookup,
      task,
      timerArray,
      workTypeLookup,
    } = this.props;
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutes = computeIntervalSums(intervals, now);
    const workTypeChangeBlockedReason = workTypeChangeBlocked(
      task,
      timerMinutes,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationArray,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (workTypeChangeBlockedReason) {
      this.setState({
        workTypeChangeBlockedDialogOpen: true,
        workTypeChangeBlockedReason,
      });
    } else {
      this.setState({priceGroupDialogOpen: true});
    }
  }
  @bind
  handlePriceGroupDialogOk(priceGroupURL: PriceGroupUrl): void {
    this.setState({priceGroupDialogOpen: false});
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({priceGroup: priceGroupURL}, task));
    focusButton(this.selectPriceGroupButton);
  }
  @bind
  handlePriceGroupDialogCancel(): void {
    this.setState({priceGroupDialogOpen: false});
    focusButton(this.selectPriceGroupButton);
  }
  @bind
  handleRemoveMachine(url: MachineUrl): void {
    const {
      customerSettings,
      dispatch,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      task,
      timerArray,
      timerMinutesMap,
      workTypeLookup,
    } = this.props;
    const machineRemovalBlockedReason = machineRemovalBlocked(
      task,
      url,
      timerMinutesMap,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (machineRemovalBlockedReason) {
      this.setState({
        machineRemovalBlockedDialogOpen: true,
        machineRemovalBlockedReason,
      });
    } else {
      const oldMachines = task.machineuseSet || [];
      const newMachines = oldMachines.filter((m) => m.machine !== url);
      dispatch(actions.updateDiff({machineuseSet: newMachines}, task));
    }
  }
  @bind
  handleMachineRemovalBlockedDialogClose(): void {
    this.setState({machineRemovalBlockedDialogOpen: false});
  }
  @bind
  handleProductSelectButton(): void {
    this.setState({productDialogOpen: true});
  }
  @bind
  handleProductDialogOk(urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>): void {
    this.setState({
      productDialogOpen: false,
    });
    const {productArray, productGroupLookup, productLookup, task, update} = this.props;
    const patch = addToProductUses(
      task.productUses || {},
      urlOrURLs,
      productArray,
      productLookup,
      productGroupLookup,
      this.props.customerSettings,
      this.props.currentUserURL,
    );
    update(task.url, patch);
  }
  @bind
  handleProductDialogCancel(): void {
    this.setState({productDialogOpen: false});
  }
  @bind
  handleMachineOperatorSelectButton(): void {
    this.setState({machineOperatorDialogOpen: true});
  }
  @bind
  handleMachineOperatorDialogOk(url: UserUrl): void {
    const {dispatch, task} = this.props;
    this.setState({machineOperatorDialogOpen: false});
    dispatch(actions.updateDiff({machineOperator: url}, task));
    focusButton(this.selectMachineOperatorButton);
  }
  @bind
  handleMachineOperatorDialogCancel(): void {
    this.setState({machineOperatorDialogOpen: false});
    focusButton(this.selectMachineOperatorButton);
  }
  @bind
  handleRemoveMachineOperator(): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({machineOperator: null}, task));
  }
  @bind
  handleWorkplaceSelectButton(): void {
    this.setState({workplaceDialogOpen: true});
  }
  @bind
  handleWorkplaceClearButton(): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({relatedWorkplace: null}, task));
  }
  @bind
  handleWorkplaceDialogNone(): void {
    this.setState({workplaceDialogOpen: false});
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({relatedWorkplace: null}, task));
  }
  @bind
  handleWorkplaceDialogOk(url: LocationUrl): void {
    this.setState({workplaceDialogOpen: false});
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({relatedWorkplace: url}, task));
  }
  @bind
  handleWorkplaceDialogCancel(): void {
    this.setState({workplaceDialogOpen: false});
  }
  @bind
  handleRequestEditTransportLogDialog(): void {
    const {task} = this.props;
    const taskURL = task.url;
    const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
    if (!transportLog && this.props.customerSettings.dontShowTransportLogDialog) {
      const id = urlToId(task.url);
      const logURL = instanceURL("transportLog", id);
      const params: TransportLog = {
        ...emptyTransportLog,
        id,
        task: taskURL,
        url: logURL,
      };
      this.props.create(params);
    } else {
      this.setState({
        editTransportLogDialogTask: task,
        editTransportLogDialogTransportLog: transportLog || null,
      });
    }
  }
  @bind
  handleAddTransportLog(): void {
    this.handleRequestEditTransportLogDialog();
  }
  @bind
  handleTransportLogDialogCancel(): void {
    this.setState({
      editTransportLogDialogTask: null,
      editTransportLogDialogTransportLog: null,
    });
  }
  @bind
  handleTransportLogDialogOk({
    amountPerTrip,
    haRequired,
    kmRequired,
    relatedUnit,
    unit,
  }: {
    amountPerTrip: number | null;
    haRequired: boolean;
    kmRequired: boolean;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    const task = this.state.editTransportLogDialogTask;
    if (!task) {
      return;
    }
    const oldTransportLog = this.state.editTransportLogDialogTransportLog;
    this.setState({
      editTransportLogDialogTask: null,
      editTransportLogDialogTransportLog: null,
    });
    if (!oldTransportLog) {
      const id = urlToId(task.url);
      const url = instanceURL("transportLog", id);
      const taskURL = task.url;
      const newTransportLog = {
        amountPerTrip,
        haRequired,
        id,
        kmRequired,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        task: taskURL,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
        url,
      };
      this.props.create(newTransportLog);
    } else {
      const newTransportLog: TransportLog = {
        ...oldTransportLog,
        amountPerTrip,
        haRequired,
        kmRequired,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
      };
      const patch = computePatch(newTransportLog, oldTransportLog);
      if (patch) {
        this.props.update(oldTransportLog.url, patch);
      }
    }
  }
  @bind
  handleRequestDeliveryLocationDialog(deliveryLocation: DeliveryLocation | null): void {
    let deliveryLocationDialog: TaskDetailsState["deliveryLocationDialog"];
    if (deliveryLocation) {
      deliveryLocationDialog = {
        amount: deliveryLocation.amount,
        areaHa: deliveryLocation.areaHa,
        customer:
          (deliveryLocation.customer && this.props.customerLookup(deliveryLocation.customer)) ||
          null,
        location:
          (deliveryLocation.relatedLocation &&
            this.props.locationLookup(deliveryLocation.relatedLocation)) ||
          null,
        note: deliveryLocation.note,
        open: true,
        relatedUnit:
          (deliveryLocation.relatedUnit && this.props.unitLookup(deliveryLocation.relatedUnit)) ||
          null,
        unit: deliveryLocation.unit,
        url: deliveryLocation.url,
      };
    } else {
      const {task} = this.props;
      const orderURL = task.order;
      const order = orderURL && this.props.orderLookup(orderURL);
      const customerURL = order && order.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || null;
      deliveryLocationDialog = {
        amount: null,
        areaHa: null,
        customer,
        location: null,
        note: "",
        open: true,
        relatedUnit: null,
        unit: "",
        url: null,
      };
    }
    this.setState({deliveryLocationDialog});
  }
  @bind
  handleDeliveryLocationDialogOk(params: {
    amount: number | null;
    areaHa: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    const {amount, areaHa, customer, location, note, relatedUnit, unit} = params;
    const existingURL = this.state.deliveryLocationDialog.url;
    if (existingURL) {
      const oldDeliveryLocation = this.props.deliveryLocationLookup(existingURL);
      if (!oldDeliveryLocation) {
        return;
      }
      const newDeliveryLocation = {
        ...oldDeliveryLocation,
        amount,
        areaHa,
        customer: customer ? customer.url : null,
        note,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
      };
      const patch = computePatch(newDeliveryLocation, oldDeliveryLocation);
      if (patch) {
        this.props.update(oldDeliveryLocation.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("deliveryLocation", id);
      const {task} = this.props;
      const taskURL = task.url;
      const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
      if (!transportLog) {
        return;
      }
      const transportLogURL = transportLog.url;
      const order = this.props.deliveryLocationArray.filter(
        (d) => d.transportlog === transportLogURL,
      ).length;
      const newDeliveryLocation: DeliveryLocation = {
        amount,
        areaHa,
        customer: customer ? customer.url : null,
        id,
        note,
        order,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        task: taskURL,
        transportlog: transportLogURL,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
        url,
      };
      this.props.create(newDeliveryLocation);
    }
    this.handleDeliveryLocationDialogCancel();
  }
  @bind
  handleDeliveryLocationDialogCancel(): void {
    const deliveryLocationDialog: TaskDetailsState["deliveryLocationDialog"] = {
      amount: null,
      areaHa: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
    };
    this.setState({deliveryLocationDialog});
  }
  @bind
  handleRequestPickupLocationDialog(pickupLocation: PickupLocation | null): void {
    let pickupLocationDialog: TaskDetailsState["pickupLocationDialog"];
    if (pickupLocation) {
      pickupLocationDialog = {
        amount: pickupLocation.amount,
        customer:
          (pickupLocation.customer && this.props.customerLookup(pickupLocation.customer)) || null,
        location:
          (pickupLocation.relatedLocation &&
            this.props.locationLookup(pickupLocation.relatedLocation)) ||
          null,
        note: pickupLocation.note || "",
        open: true,
        relatedUnit:
          (pickupLocation.relatedUnit && this.props.unitLookup(pickupLocation.relatedUnit)) || null,
        unit: pickupLocation.unit,
        url: pickupLocation.url,
      };
    } else {
      const {task} = this.props;
      const orderURL = task && task.order;
      const order = orderURL ? this.props.orderLookup(orderURL) : null;
      const customerURL = order && order.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || null;
      pickupLocationDialog = {
        amount: null,
        customer,
        location: null,
        note: "",
        open: true,
        relatedUnit: null,
        unit: "",
        url: null,
      };
    }
    this.setState({pickupLocationDialog});
  }
  @bind
  handlePickupLocationDialogOk(params: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    const {amount, customer, location, note, relatedUnit, unit} = params;
    const existingURL = this.state.pickupLocationDialog.url;
    if (existingURL) {
      const oldPickupLocation = this.props.pickupLocationLookup(existingURL);
      if (!oldPickupLocation) {
        return;
      }
      const newPickupLocation = {
        ...oldPickupLocation,
        amount,
        customer: customer ? customer.url : null,
        note,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
      };
      const patch = computePatch(newPickupLocation, oldPickupLocation);
      if (patch) {
        this.props.update(oldPickupLocation.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("pickupLocation", id);
      const {task} = this.props;
      const taskURL = task.url;
      const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
      if (!transportLog) {
        return;
      }
      const transportLogURL = transportLog.url;
      const order = this.props.pickupLocationArray.filter(
        (d) => d.transportlog === transportLogURL,
      ).length;
      const newPickupLocation: PickupLocation = {
        amount,
        customer: customer ? customer.url : null,
        id,
        note,
        order,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        task: taskURL,
        transportlog: transportLogURL,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
        url,
      };
      this.props.create(newPickupLocation);
    }
    this.handlePickupLocationDialogCancel();
  }
  @bind
  handlePickupLocationDialogCancel(): void {
    const pickupLocationDialog: TaskDetailsState["pickupLocationDialog"] = {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
    };
    this.setState({pickupLocationDialog});
  }

  @bind
  handleCustomerDialogOkForProject(customer: Customer): void {
    this.setState({
      customerDialogCallback: null,
    });
    const orderURL = this.props.task.order as OrderUrl;
    console.assert(orderURL);
    const order = this.props.orderLookup(orderURL);
    if (!order) {
      return;
    }
    if (customer.url !== order.customer) {
      this.changeCustomerCulture(customer.url);
    }
  }

  changeCustomerCulture(customerURL: CustomerUrl): void {
    const {customerSettings, task, taskArray} = this.props;
    const orderURL = task.order as OrderUrl;
    console.assert(orderURL);
    const order = this.props.orderLookup(orderURL);
    if (!order) {
      return;
    }
    const taskList = taskArray.filter((otherTask) => otherTask.order === orderURL);
    const {contactArray, customerLookup, unitLookup} = this.props;
    changeCustomerCulture(
      order,
      customerURL,
      null,
      {
        contactArray,
        customerLookup,
        taskList,
        unitLookup,
      },
      customerSettings,
      this.props.update,
    );
  }

  @bind
  handleCustomerDialogOk(url: CustomerUrl | null): void {
    const callback = this.state.customerDialogCallback;
    this.setState({customerDialogCallback: null});
    const customer = url && this.props.customerLookup(url);
    if (callback && customer) {
      callback(customer);
    }
  }
  @bind
  handleCustomerDialogCancel(): void {
    this.setState({customerDialogCallback: null});
  }
  @bind
  handleDepartmentSelectButton(): void {
    this.setState({departmentDialogOpen: true});
  }
  @bind
  handleDepartmentDialogOk(selected: string): void {
    const {dispatch, task} = this.props;
    this.setState({departmentDialogOpen: false});
    dispatch(actions.updateDiff({department: selected}, task));
  }
  @bind
  handleDepartmentDialogCancel(): void {
    this.setState({departmentDialogOpen: false});
  }
  @bind
  handleWorkTypeSelectButton(): void {
    const {
      customerSettings,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationArray,
      reportingSpecificationLookup,
      task,
      timerArray,
      workTypeLookup,
    } = this.props;
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutes = computeIntervalSums(intervals, now);
    const workTypeChangeBlockedReason = workTypeChangeBlocked(
      task,
      timerMinutes,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationArray,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (workTypeChangeBlockedReason) {
      this.setState({
        workTypeChangeBlockedDialogOpen: true,
        workTypeChangeBlockedReason,
      });
    } else {
      this.setState({workTypeDialogOpen: true});
    }
  }
  @bind
  handleWorkTypeDialogOk(workTypeURL: WorkTypeUrl): void {
    this.setState({
      workTypeDialogOpen: false,
    });
    const {customerSettings, dispatch, priceGroupLookup, task, workTypeLookup} = this.props;
    dispatch(actions.updateDiff({priceGroup: null, workType: workTypeURL}, task));

    const orderURL = task.order as OrderUrl;
    console.assert(orderURL);
    const order = this.props.orderLookup(orderURL);
    if (!order) {
      return;
    }
    if (
      customerSettings.askForPriceGroupSelectionAfterWorkTypeSelection &&
      workTypeURL &&
      workTypePotentialPriceGroups(workTypeURL, order.customer, workTypeLookup, priceGroupLookup)
        .length > 1
    ) {
      this.setState({
        priceGroupDialogOpen: true,
      });
      return;
    }
    focusButton(this.selectWorkTypeButton);
  }
  @bind
  handleWorkTypeDialogCancel(): void {
    this.setState({
      workTypeDialogOpen: false,
    });
    focusButton(this.selectWorkTypeButton);
  }
  @bind
  handleWorkTypeChangeBlockedDialogClose(): void {
    this.setState({workTypeChangeBlockedDialogOpen: false});
  }
  @bind
  handleSelectFieldsClick(): void {
    this.setState({fieldDialogOpen: true});
  }
  @bind
  handleFieldDialogOk(fieldURLSet: ReadonlySet<LocationUrl>): void {
    const {dispatch, locationLookup, task} = this.props;
    const oldSelected = task.fielduseSet;
    const fieldUseList = getUpdatedFieldUseList(fieldURLSet, oldSelected, locationLookup);
    dispatch(actions.updateDiff({fielduseSet: fieldUseList}, task));

    this.setState({fieldDialogOpen: false});
  }

  @bind
  handleNotesChange(fieldUse: FieldUseWithField): void {
    const {dispatch, task} = this.props;
    const newfieldUseList = [...task.fielduseSet];
    const index = newfieldUseList.findIndex(
      (field) => field.relatedField === _.get(fieldUse, ["field", "url"]),
    );
    newfieldUseList[index] = {
      fieldAreaHa: fieldUse.field.fieldAreaHa,
      fieldCrop: fieldUse.field.fieldCrop,
      geojson: fieldUse.field.geojson,
      notes: fieldUse.notes,
      relatedField: fieldUse.field.url,
    };

    dispatch(actions.updateDiff({fielduseSet: newfieldUseList}, task));
  }

  @bind
  handleRearrange(sourceField: FieldUseWithField, targetField: FieldUseWithField): void {
    const {dispatch, task} = this.props;
    const fieldUseList = task.fielduseSet;
    const sourceFieldUse = {
      fieldAreaHa: sourceField.field.fieldAreaHa,
      fieldCrop: sourceField.field.fieldCrop,
      geojson: sourceField.field.geojson,
      notes: sourceField.notes,
      relatedField: sourceField.field.url,
    };
    const sourceIndex = fieldUseList.findIndex(
      (field) => field.relatedField === sourceFieldUse.relatedField,
    );
    const targetIndex = fieldUseList.findIndex(
      (field) => field.relatedField === _.get(targetField, ["field", "url"]),
    );
    const newfieldUseList = [...fieldUseList];
    newfieldUseList.splice(sourceIndex, 1);
    newfieldUseList.splice(targetIndex, 0, sourceFieldUse);

    dispatch(actions.updateDiff({fielduseSet: newfieldUseList}, task));
  }

  @bind
  handleFieldDialogCancel(): void {
    this.setState({fieldDialogOpen: false});
  }
  @bind
  handleLocationDelete(location: DeliveryLocation | PickupLocation): void {
    this.props.remove(location.url);
  }
  getTimerStarts(): TimerStart[] {
    const {task} = this.props;
    const taskURL = task.url;
    return _.sortBy(
      this.props.timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }
  @bind
  handleInvoiceNoteChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({invoiceNote: filteredValue}, task));
  }
  @bind
  handleTaskInternalManagerNotesChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({managerInternalNotes: filteredValue}, task));
  }
  @bind
  handleNotesFromManagerChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({notesFromManager: filteredValue}, task));
  }
  @bind
  handleReferenceNumberChange(value: string): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({referenceNumber: value}, task));
  }

  @bind
  handleProjectChange(value: ProjectUrl | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({project: value}, task));
  }

  @bind
  handleAddressChange(value: string | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({address: value || undefined}, task));
  }
  @bind
  handleTimeChange(value: string | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({time: value}, task));
  }
  @bind
  handleArrivalAtLocationChange(value: string | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({arrivalAtLocation: value}, task));
  }
  @bind
  handleMinutesExpectedTotalTaskDurationChange(value: number | null): void {
    const {dispatch, task} = this.props;
    dispatch(actions.updateDiff({minutesExpectedTotalTaskDuration: value}, task));
  }

  @bind
  handleCopyButton(): void {
    const {orderLookup, task} = this.props;
    const orderURL = task ? task.order : null;
    const order = typeof orderURL === "string" ? orderLookup(orderURL) : orderURL;
    if (!task || (orderURL && !order)) {
      return;
    }
    const {
      currentRole,
      currentUserURL,
      customerSettings,
      deliveryLocationArray,
      locationLookup,
      machineLookup,
      pickupLocationArray,
      priceGroupLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      sprayLocationArray,
      sprayLogArray,
      transportLogArray,
      workTypeLookup,
      yieldDeliveryLocationArray,
      yieldLogArray,
      yieldPickupLocationArray,
    } = this.props;
    const today = dateToString(new Date());

    const overrides: Partial<Writable<Task>> = {
      createdBy: currentUserURL,
    };
    if (
      !currentRole ||
      !currentRole.manager ||
      customerSettings.taskCopyAlwaysOverridemachineOperator
    ) {
      overrides.machineOperator = currentUserURL;
    }
    if (task.date == null || task.date < today) {
      overrides["date"] = today;
    }

    if (orderURL) {
      overrides.order = orderURL;
    }

    const copyResult = copyTaskWithLogsLocations(
      task,
      customerSettings.taskCopyFields,
      overrides,
      {
        deliveryLocationArray,
        locationLookup,
        machineLookup,
        pickupLocationArray,
        priceGroupLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        sprayLocationArray,
        sprayLogArray,
        transportLogArray,
        workTypeLookup,
        yieldDeliveryLocationArray,
        yieldLogArray,
        yieldPickupLocationArray,
      },
      this.props.dispatch,
      this.context,
      customerSettings,
    );

    copyResult.forEach((instance) => {
      this.props.createOrUpdate(instance);
    });
    const taskCopyID = urlToId(copyResult[0].url);
    window.setTimeout(() => {
      this.props.go("/taskDetails/:id", {id: taskCopyID}, {}, "REPLACE");
    }, 0);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerSettings,
      daysAbsenceArray,
      hoursAbsenceArray,
      locationLookup,
      orderLookup,
      reportingSpecificationLookup,
      task,
      timerArray,
    } = this.props;
    const orderURL = task.order;
    const order = orderURL && this.props.orderLookup(orderURL);
    const customerURL = order ? order.customer : null;
    const userURL = this.props.currentUserURL;
    const role = this.props.currentRole;
    const userIsSeniorMachineOperator = !!(role && role.seniorMachineOperator);
    const userIsNotManager = !!(role && !role.manager);
    const machineOperatorURL = task.machineOperator;
    const userIsOther = userURL !== machineOperatorURL;
    const userIsOtherMachineOperator = userIsOther && userIsNotManager;

    const validated = !!task.validatedAndRecorded;
    const completed = !!task.completed;
    const deletable =
      !validated && (!completed || !userIsNotManager) && !userIsOtherMachineOperator;
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const hasActivity = !!intervals.length;

    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutes = computeIntervalSums(intervals, now);

    let referenceNumberBlock;
    if (customerSettings.enableTaskReferenceNumber) {
      referenceNumberBlock = (
        <Cell palm="12/12">
          <TrimTextField
            disabled={validated || userIsOtherMachineOperator}
            inputProps={{maxLength: 255}}
            label={
              customerSettings.taskReferenceNumberLabel || formatMessage(messages.referenceNumber)
            }
            margin="dense"
            value={task.referenceNumber || ""}
            onChange={this.handleReferenceNumberChange}
          />
        </Cell>
      );
    }

    let timeBlock: JSX.Element | undefined;
    if (customerSettings.taskShowTimeField) {
      timeBlock = (
        <Cell palm="12/12">
          <TimeField
            fullWidth
            disabled={
              validated || (completed && !!userIsNotManager) || !!userIsOtherMachineOperator
            }
            label={formatMessage(messages.time)}
            margin="dense"
            value={task.time || undefined}
            onChange={this.handleTimeChange}
          />
        </Cell>
      );
    }

    let machineOperator = null;
    const allowMachineOperatorRemoval = deletable && !hasActivity;
    const disableMachineOperatorSelection =
      !customerSettings.allowCustomerTaskEmployeeChange || !allowMachineOperatorRemoval;
    if (machineOperatorURL && this.props.userLookup(machineOperatorURL)) {
      const profile = this.props.userUserProfileLookup(machineOperatorURL);

      let absent;
      const machineOperatorAbsent = checkUserAbsence(
        machineOperatorURL,
        task,
        orderLookup,
        daysAbsenceArray,
        hoursAbsenceArray,
        this.props.customerSettings.absenceWarningDisabledFor,
      );
      if (machineOperatorAbsent) {
        absent = (
          <div style={{color: "red"}}>
            {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
              <FormattedMessage
                defaultMessage="Den valgte maskinfører har registreret fravær på den valgte dato"
                id="order-instance.header.machine-operator-absent"
                tagName="h4"
              />
            ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
              <FormattedMessage
                defaultMessage="Den valgte medarbejder har registreret fravær på den valgte dato"
                id="order-instance.header.employee-absent"
                tagName="h4"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Den valgte chauffør har registreret fravær på den valgte dato"
                id="order-instance.header.chauffeur-absent"
                tagName="h4"
              />
            )}
          </div>
        );
      }

      let hasOtherTasks: JSX.Element | undefined;
      if (customerSettings.employeeSameDayTasksWarning) {
        const machineOperatorOtherTaskCount = getOtherTaskCount(task, this.props.taskArray);
        if (machineOperatorOtherTaskCount > 0) {
          hasOtherTasks = (
            <TaskCountMessage
              customerSettings={customerSettings}
              machineOperatorOtherTaskCount={machineOperatorOtherTaskCount}
            />
          );
        }
      }

      const machineOperatorChipOptionalClickHandleProps: ChipProps = {};
      if (allowMachineOperatorRemoval) {
        machineOperatorChipOptionalClickHandleProps.onDelete = this.handleRemoveMachineOperator;
      }

      machineOperator = (
        <div>
          <Chip
            avatar={
              <Avatar
                style={{
                  backgroundColor: colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND,
                  color: matchingTextColor(colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND),
                }}
              >
                {profile ? profile.alias : ""}
              </Avatar>
            }
            label={profile && profile.name}
            {...machineOperatorChipOptionalClickHandleProps}
          />
          {absent}
          {hasOtherTasks}
        </div>
      );
    }

    let workType = null;
    let priceGroup = null;

    const workTypeURL = task.workType;
    const workTypeData = workTypeURL ? this.props.workTypeLookup(workTypeURL) : null;
    if (workTypeData) {
      const workTypeString = getWorkTypeString(workTypeData);
      workType = <div>{workTypeString}</div>;
    }
    const priceGroupURL = task.priceGroup;
    const priceGroupData = priceGroupURL ? this.props.priceGroupLookup(priceGroupURL) : null;
    if (priceGroupData) {
      priceGroup = <div>({priceGroupData.name})</div>;
    }

    let addProductButton = null;
    if (!customerSettings.noProducts && customerSettings.enableAddProducts) {
      addProductButton = (
        <Button
          color="secondary"
          disabled={validated}
          variant="contained"
          onClick={this.handleProductSelectButton}
        >
          {customerSettings.materialUseAlternativeText
            ? formatMessage(messages.alternativeAddProductButton)
            : formatMessage(messages.addProductButton)}
        </Button>
      );
    }

    const departmentID = task.department;
    const department = <div>{getDepartmentName(departmentID, this.props.customerSettings)}</div>;

    const disableDelete = completed || validated || hasActivity;

    const deleteDialog = (
      <DeleteDialog
        key="delete-dialog"
        open={this.state.deleteDialogOpen}
        onCancel={this.handleDeleteDialogCancel}
        onOk={this.handleDeleteDialogOk}
      >
        <FormattedMessage defaultMessage="Slet opgave?" id="task-instance.label.do-delete-task" />
      </DeleteDialog>
    );
    const machineDialog = (
      <MachinePriceGroupWizard
        key="machine-dialog"
        open={this.state.machineDialogOpen}
        task={task}
        onCancel={this.handleMachineDialogCancel}
        onOk={this.handleMachineDialogOk}
      />
    );

    const machineuseSet =
      customerSettings.noExternalTaskWorkType && task ? task.machineuseSet : undefined;
    const machines = machineuseSet
      ? machineuseSet
          .map((machineUse) => this.props.machineLookup(machineUse.machine))
          .filter(notUndefined)
      : undefined;

    const priceGroups = Array.from(
      getRelevantPriceGroupSet(this.props.task, {
        timerLookup: this.props.timerLookup,
        timerMinutesMap: this.props.timerMinutesMap,
      }),
    )
      .map(this.props.priceGroupLookup)
      .filter(notUndefined);

    const productDialog = customerSettings.productImageSelection ? (
      <ProductGroupTreeDialog
        key="product-dialog"
        open={this.state.productDialogOpen}
        onCancel={this.handleProductDialogCancel}
        onOk={this.handleProductDialogOk}
      />
    ) : (
      <ConnectedProductDialog
        key="product-dialog"
        machines={machines}
        open={this.state.productDialogOpen}
        preferredCategory="recentlyUsed"
        preferredProductURLs={
          customerSettings.enableRecentlyUsedProducts
            ? readProductUseLog(
                this.props.productUseLogArray,
                this.props.productLookup,
                this.props.task,
              )
            : undefined
        }
        priceGroups={priceGroups}
        workType={
          !customerSettings.noExternalTaskWorkType && task && task.workType
            ? this.props.workTypeLookup(task.workType)
            : undefined
        }
        onCancel={this.handleProductDialogCancel}
        onOk={this.handleProductDialogOk}
      />
    );
    const machineOperatorDialog = (
      <ConnectedMachineOperatorDialog
        key="machine-operator-dialog"
        open={this.state.machineOperatorDialogOpen}
        onCancel={this.handleMachineOperatorDialogCancel}
        onOk={this.handleMachineOperatorDialogOk}
      />
    );

    const workplaceDialog = (
      <LocationSelectCreateDialog
        key="workplace-dialog"
        hideFieldLocations
        includeWorkplaceOnlyLocations
        customerURL={customerURL}
        includeLogOnlyLocations={false}
        logOnlyLocation={false}
        machineOperator={task.machineOperator || undefined}
        open={!!this.state.workplaceDialogOpen}
        titleVariant="WORKPLACE"
        onCancel={this.handleWorkplaceDialogCancel}
        onNone={
          !this.props.customerSettings.requireWorkplaceIfExists
            ? this.handleWorkplaceDialogNone
            : undefined
        }
        onOk={this.handleWorkplaceDialogOk}
      />
    );
    const departmentDialog = (
      <ConnectedDepartmentDialog
        key="department-dialog"
        open={!!this.state.departmentDialogOpen}
        onCancel={this.handleDepartmentDialogCancel}
        onOk={this.handleDepartmentDialogOk}
      />
    );
    let priceGroupAlternatives = new Set<string>();
    if (workTypeURL) {
      const w = this.props.workTypeLookup(workTypeURL);
      const workTypePriceGroups = w ? w.pricegroups : [];
      priceGroupAlternatives = new Set(workTypePriceGroups);
    }
    const noPriceGroupChoicePossible = priceGroupAlternatives.size <= 1;

    const priceGroupDialog = (
      <ConnectedLogLegalPriceGroupDialog
        key="price-group-dialog"
        customerUrl={customerURL}
        open={this.state.priceGroupDialogOpen}
        task={task}
        workTypeURL={task.workType || undefined}
        onCancel={this.handlePriceGroupDialogCancel}
        onOk={this.handlePriceGroupDialogOk}
      />
    );
    const taskURL = task.url;
    const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
    const deliveryLocationDialog = (
      <DeliveryLocationDialog
        key="delivery-location-dialog"
        amount={
          this.state.deliveryLocationDialog.amount != null
            ? this.state.deliveryLocationDialog.amount
            : undefined
        }
        areaHa={
          this.state.deliveryLocationDialog.areaHa != null
            ? this.state.deliveryLocationDialog.areaHa
            : undefined
        }
        customer={this.state.deliveryLocationDialog.customer || undefined}
        customerSettings={this.props.customerSettings}
        haRequired={transportLog ? transportLog.haRequired : undefined}
        isNew={!this.state.deliveryLocationDialog.url}
        location={this.state.deliveryLocationDialog.location || undefined}
        note={this.state.deliveryLocationDialog.note}
        open={this.state.deliveryLocationDialog.open}
        relatedUnit={
          (transportLog &&
            transportLog.relatedUnit &&
            this.props.unitLookup(transportLog.relatedUnit)) ||
          this.state.deliveryLocationDialog.relatedUnit ||
          undefined
        }
        unit={
          transportLog ? transportLog.unit || this.state.deliveryLocationDialog.unit : undefined
        }
        onCancel={this.handleDeliveryLocationDialogCancel}
        onOk={this.handleDeliveryLocationDialogOk}
      />
    );
    const pickupLocationDialog = (
      <PickupLocationDialog
        key="pickup-location-dialog"
        amount={
          this.state.pickupLocationDialog.amount != null
            ? this.state.pickupLocationDialog.amount
            : undefined
        }
        customer={this.state.pickupLocationDialog.customer || undefined}
        isNew={!this.state.pickupLocationDialog.url}
        location={this.state.pickupLocationDialog.location || undefined}
        note={this.state.pickupLocationDialog.note}
        open={this.state.pickupLocationDialog.open}
        relatedUnit={
          (transportLog &&
            transportLog.relatedUnit &&
            this.props.unitLookup(transportLog.relatedUnit)) ||
          this.state.pickupLocationDialog.relatedUnit ||
          undefined
        }
        unit={transportLog ? transportLog.unit || this.state.pickupLocationDialog.unit : undefined}
        onCancel={this.handlePickupLocationDialogCancel}
        onCustomerChanged={this.handleCustomerDialogOk}
        onOk={this.handlePickupLocationDialogOk}
      />
    );
    const transportLogDialog = (
      <TransportLogDialog
        key="transport-log-dialog"
        amountPerTrip={
          this.state.editTransportLogDialogTransportLog &&
          this.state.editTransportLogDialogTransportLog.amountPerTrip != null
            ? this.state.editTransportLogDialogTransportLog.amountPerTrip
            : undefined
        }
        haRequired={
          this.state.editTransportLogDialogTransportLog
            ? this.state.editTransportLogDialogTransportLog.haRequired
            : undefined
        }
        kmRequired={
          this.state.editTransportLogDialogTransportLog
            ? this.state.editTransportLogDialogTransportLog.kmRequired
            : undefined
        }
        open={!!this.state.editTransportLogDialogTask}
        relatedUnit={
          this.state.editTransportLogDialogTransportLog &&
          this.state.editTransportLogDialogTransportLog.relatedUnit
            ? this.props.unitLookup(this.state.editTransportLogDialogTransportLog.relatedUnit)
            : undefined
        }
        unit={
          this.state.editTransportLogDialogTransportLog
            ? this.state.editTransportLogDialogTransportLog.unit
            : undefined
        }
        onCancel={this.handleTransportLogDialogCancel}
        onOk={this.handleTransportLogDialogOk}
      />
    );
    const customerDialog = (
      <CustomerSelectCreateDialog
        key="customer-dialog"
        open={!!this.state.customerDialogCallback}
        onCancel={this.handleCustomerDialogCancel}
        onOk={this.handleCustomerDialogOk}
      />
    );
    const workTypeDialog = (
      <ConnectedLogLegalExternalWorkTypeDialog
        key="work-type-dialog"
        customerUrl={customerURL}
        open={!!this.state.workTypeDialogOpen}
        task={task}
        onCancel={this.handleWorkTypeDialogCancel}
        onOk={this.handleWorkTypeDialogOk}
      />
    );

    const workTypeChangeBlockedDialog = (
      <WorkTypeChangeBlockedDialog
        key="work-type-change-blocked-dialog"
        blockedReason={this.state.workTypeChangeBlockedReason}
        open={this.state.workTypeChangeBlockedDialogOpen}
        onClose={this.handleWorkTypeChangeBlockedDialogClose}
      />
    );

    const machineRemovalBlockedDialog = (
      <MachineRemovalBlockedDialog
        key="machine-removal-blocked-dialog"
        blockedReason={this.state.machineRemovalBlockedReason}
        open={this.state.machineRemovalBlockedDialogOpen}
        onClose={this.handleMachineRemovalBlockedDialogClose}
      />
    );

    const dialogs = task.validatedAndRecorded
      ? []
      : [
          deliveryLocationDialog,
          pickupLocationDialog,
          departmentDialog,
          priceGroupDialog,
          workplaceDialog,
          deleteDialog,
          machineDialog,
          machineOperatorDialog,
          productDialog,
          transportLogDialog,
          customerDialog,
          workTypeDialog,
          workTypeChangeBlockedDialog,
          machineRemovalBlockedDialog,
        ];
    let inlinedMachineUseList: InlinedMachineUse[] = [];
    if (task.machineuseSet && task.machineuseSet.length) {
      inlinedMachineUseList = task.machineuseSet
        .map(
          inlineMachineUse.bind(null, {
            machineLookup: this.props.machineLookup,
            priceGroupLookup: this.props.priceGroupLookup,
          }),
        )
        .filter(notNull);
    }
    const machineRemovalAllowed = !task.order || !!customerSettings.allowCustomerTaskMachineChange;
    const deletableMachineURLSet = machineRemovalAllowed
      ? new Set(inlinedMachineUseList.map((machineUse) => machineUse.machine.url))
      : new Set<string>();
    const editable = !(validated || (completed && userIsNotManager) || userIsOtherMachineOperator);

    const fieldList = this.props.locationArray.filter(
      (field) => field.active && !!field.geojson && field.customer === customerURL,
    );
    const taskFieldUseList = task.fielduseSet ? task.fielduseSet.slice() : [];

    if (fieldList.length && !task.validatedAndRecorded) {
      const reportingSpecification =
        task?.reportingSpecification && !task?.logSkipped
          ? this.props.reportingSpecificationLookup(task.reportingSpecification)
          : undefined;

      const taskLogUsingFields = !!(
        reportingSpecification?.fieldsUsedFor && reportingSpecification?.fieldsUsedFor !== "unused"
      );

      const usedFieldsUrls =
        taskLogUsingFields && task.reportingLocations && task.reportingLog
          ? getUsedFieldUrlSet(task.reportingLocations, task.reportingLog)
          : undefined;

      const fieldDialog = customerSettings.addEditLogLocationSkipsCustomerSelection ? (
        <SelectTaskFields
          logSpecification={reportingSpecification}
          open={this.state.fieldDialogOpen}
          task={task}
          onClose={this.handleFieldDialogCancel}
        />
      ) : (
        <FieldMultiSelectionDialog
          key="field-dialog"
          customerURL={customerURL || undefined}
          open={this.state.fieldDialogOpen}
          readonlySet={usedFieldsUrls}
          selected={taskFieldUseList}
          onCancel={this.handleFieldDialogCancel}
          onOk={this.handleFieldDialogOk}
        />
      );

      dialogs.push(fieldDialog);
    }
    const allowMoreThanTwoMachines =
      customerSettings.alwaysAllowMoreThanTwoMachines ||
      customerSettings.allowMoreThanTwoMachinesForDepartments.includes(task.department);
    const fieldExtraNotes = new Map<string, string>();
    const orderFieldUseList = order && order.orderfielduseSet;
    if (orderFieldUseList && !!orderFieldUseList.length) {
      orderFieldUseList.forEach((fieldUse) => {
        const {notes} = fieldUse;
        if (notes) {
          const fieldURL = fieldUse.relatedField;
          fieldExtraNotes.set(fieldURL, notes);
        }
      });
    }
    const allowMachineUse = allowMachineUseForWorktype(workTypeData || undefined);

    const genericPrimaryTimer = getGenericEffectiveTimer(timerArray);
    const primaryTimerURL = genericPrimaryTimer ? genericPrimaryTimer.url : null;
    const effectiveMinutes = (primaryTimerURL && timerMinutes.get(primaryTimerURL)) || 0;
    const location = task.relatedWorkplace
      ? this.props.locationLookup(task.relatedWorkplace)
      : null;
    const locationString = location?.name || location?.address || "";
    const reportingSpecification =
      task.reportingSpecification && !task.logSkipped
        ? reportingSpecificationLookup(task.reportingSpecification)
        : undefined;
    let readonlyPriceItems: Set<string> | undefined;
    if (reportingSpecification) {
      readonlyPriceItems = getReadonlyPriceItems(
        reportingSpecification,
        sortByOrderMember(Object.values(task.priceItemUses || {})),
        this.props.priceItemLookup,
        this.props.unitLookup,
      );
    }

    let workplaceBlock;
    if (customerSettings.taskOrderTaskShowWorkPlace) {
      const hasSelectableWorkplaces = this.props.locationArray.some(
        (l) => !l.logOnlyLocation && l.customer === customerURL && l.active,
      );
      const workplaceButtonEnabled =
        customerURL &&
        (hasSelectableWorkplaces || allowWorkplaceCreate(customerSettings, role)) &&
        !validated &&
        (!userIsNotManager || userIsSeniorMachineOperator || (!userIsOther && !completed));
      workplaceBlock = (
        <Grid>
          <Cell palm="12/12">
            <Button
              color="secondary"
              disabled={!workplaceButtonEnabled}
              variant="contained"
              onClick={this.handleWorkplaceSelectButton}
            >
              {formatMessage(messages.selectWorkplaceButton)}
            </Button>
            <IconButton
              disabled={!task.relatedWorkplace || !workplaceButtonEnabled}
              onClick={this.handleWorkplaceClearButton}
            >
              <CloseIcon />
            </IconButton>
            <div>{locationString}</div>
          </Cell>
        </Grid>
      );
    }

    return (
      <PageLayout withPadding dialogs={dialogs} toolbar={formatMessage(messages.taskTitle)}>
        <div>
          <Card>
            <CardContent>
              <Grid>
                {referenceNumberBlock}
                <MachineBlock
                  ref={this.machineBlock}
                  allowMachineUse={allowMachineUse}
                  allowMaxOneMachine={workTypeData?.allowMaxOneMachine || false}
                  customerSettings={this.props.customerSettings}
                  deletableMachineURLSet={deletableMachineURLSet}
                  editable={customerSettings.allowCustomerTaskMachineChange && editable}
                  machineUseList={inlinedMachineUseList}
                  onRequestMachineAdd={this.handleMachineSelectButton}
                  onRequestMachineRemove={this.handleRemoveMachine}
                />
                {customerSettings.enableExternalTaskDepartmentField ? (
                  <Cell palm="12/12">
                    <Typography variant="body1">
                      <FormattedMessage
                        defaultMessage="Afdeling"
                        id="order-instance.header.department"
                      />
                    </Typography>
                    <Button
                      color="secondary"
                      disabled={
                        validated ||
                        (completed && !!userIsNotManager) ||
                        !!userIsOtherMachineOperator ||
                        (customerSettings.onlyAdminCanChangeDepartment && !!userIsNotManager)
                      }
                      variant="contained"
                      onClick={this.handleDepartmentSelectButton}
                    >
                      {formatMessage(messages.selectDepartmentButton)}
                    </Button>
                    {department}
                  </Cell>
                ) : null}
                <Cell palm="12/12">
                  <Typography variant="body1">
                    {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
                      <FormattedMessage
                        defaultMessage="Maskinfører"
                        id="order-instance.header.machine-operator"
                      />
                    ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
                      <FormattedMessage
                        defaultMessage="Medarbejder"
                        id="order-instance.header.employee"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Chauffør"
                        id="order-instance.header.chauffeur"
                      />
                    )}
                  </Typography>
                  <Button
                    ref={this.selectMachineOperatorButton}
                    color="secondary"
                    disabled={validated || userIsNotManager || disableMachineOperatorSelection}
                    variant="contained"
                    onClick={this.handleMachineOperatorSelectButton}
                  >
                    {formatMessage(messages.selectMachineOperatorButton)}
                  </Button>
                  {machineOperator}
                </Cell>
                {!customerSettings.noExternalTaskWorkType ? (
                  <Cell palm="12/12">
                    <Typography variant="body1">
                      <FormattedMessage defaultMessage="Arbejdsområde" />
                    </Typography>
                    {customerSettings.enableExternalTaskWorkTypeAndVariantSwitch ? (
                      <Button
                        ref={this.selectWorkTypeButton}
                        color="secondary"
                        disabled={
                          validated || (completed && userIsNotManager) || userIsOtherMachineOperator
                        }
                        variant="contained"
                        onClick={this.handleWorkTypeSelectButton}
                      >
                        {formatMessage(messages.selectWorkTypeButton)}
                      </Button>
                    ) : null}
                    {workType}
                    <br />
                    {customerSettings.enableExternalTaskWorkTypeAndVariantSwitch ? (
                      <Button
                        ref={this.selectPriceGroupButton}
                        color="secondary"
                        disabled={
                          validated ||
                          (completed && userIsNotManager) ||
                          userIsOtherMachineOperator ||
                          noPriceGroupChoicePossible
                        }
                        variant="contained"
                        onClick={this.handlePriceGroupSelectButton}
                      >
                        {formatMessage(messages.selectPriceGroupButton)}
                      </Button>
                    ) : null}
                    {priceGroup}
                  </Cell>
                ) : null}
                {customerSettings.enableProjects && (
                  <Cell palm="12/12">
                    <ProjectBlock
                      clearDisabled={!task.project}
                      customerUrl={customerURL}
                      hideButtons={
                        userIsNotManager || !customerSettings.machineOperatorsCanChooseProject
                      }
                      projectUrl={task.project}
                      selectDisabled={!customerURL}
                      onCustomerChanged={this.handleCustomerDialogOk}
                      onProjectChanged={this.handleProjectChange}
                    />
                  </Cell>
                )}
              </Grid>
              {workplaceBlock}
              <Grid>
                <Cell palm="12/12">
                  {userIsNotManager ? (
                    <h4>Dato: {formatDate(new Date(task.date || new Date()))}</h4>
                  ) : (
                    <DateField
                      autoOk
                      fullWidth
                      disabled={validated}
                      label={formatMessage(messages.date)}
                      margin="dense"
                      value={task.date}
                      onChange={this.handleDateChange}
                    />
                  )}
                </Cell>
                {timeBlock}
                {customerSettings.showArrivalAtLocationField ? (
                  <Cell palm="12/12">
                    <TimeField
                      fullWidth
                      disabled={
                        validated ||
                        (completed && !!userIsNotManager) ||
                        !!userIsOtherMachineOperator
                      }
                      label={formatMessage(messages.arrivalAtLocation)}
                      margin="dense"
                      value={task.arrivalAtLocation != null ? task.arrivalAtLocation : undefined}
                      onChange={this.handleArrivalAtLocationChange}
                    />
                  </Cell>
                ) : null}
                {customerSettings.enableTaskEstimation &&
                (!userIsNotManager || userIsSeniorMachineOperator) ? (
                  <Cell palm="12/12">
                    <MinutesField
                      fullWidth
                      disabled={validated}
                      label={formatMessage(messages.expectedTotalTaskDuration)}
                      margin="dense"
                      value={task.minutesExpectedTotalTaskDuration}
                      onChange={this.handleMinutesExpectedTotalTaskDurationChange}
                    />
                  </Cell>
                ) : null}
              </Grid>
              <Grid>
                <Cell palm="12/12">
                  {customerSettings.enableTransportLog ? (
                    transportLog ? (
                      <TransportLogBlock
                        disabled={validated}
                        transportLog={transportLog}
                        onRequestDeliveryLocationDialog={this.handleRequestDeliveryLocationDialog}
                        onRequestEditTransportLogDialog={this.handleRequestEditTransportLogDialog}
                        onRequestLocationDelete={this.handleLocationDelete}
                        onRequestPickupLocationDialog={this.handleRequestPickupLocationDialog}
                      />
                    ) : (
                      <Button
                        color="secondary"
                        disabled={validated}
                        variant="contained"
                        onClick={this.handleAddTransportLog}
                      >
                        {formatMessage(messages.addTransportLog)}
                      </Button>
                    )
                  ) : null}
                </Cell>
              </Grid>
              {reportingSpecification && order ? (
                <LogPlaces
                  logSpecification={reportingSpecification}
                  order={order}
                  readonly={
                    validated || (completed && userIsNotManager) || userIsOtherMachineOperator
                  }
                  task={task}
                  userIsOtherMachineOperator={userIsOtherMachineOperator}
                />
              ) : null}
              {order && order.notes ? (
                <Grid>
                  <Cell>
                    <FormattedMessage
                      defaultMessage="Noter på ordre:"
                      id="task-details.header.notes-from-order"
                      tagName="h4"
                    />
                    <div>{order && order.notes}</div>
                  </Cell>
                </Grid>
              ) : null}
              {!userIsNotManager && customerSettings.enableTaskInternalManagerNotes ? (
                <Grid>
                  <Cell palm="12/12">
                    <ThrottledTextField
                      fullWidth
                      multiline
                      disabled={validated}
                      label={formatMessage(messages.taskInternalManagerNotes)}
                      margin="dense"
                      maxRows={30}
                      minRows={2}
                      value={task.managerInternalNotes}
                      variant="outlined"
                      onChange={this.handleTaskInternalManagerNotesChange}
                    />
                  </Cell>
                </Grid>
              ) : null}
              {(!userIsNotManager || customerSettings.machineOperaterCanEditInvoiceNote) &&
              customerSettings.showInvoiceNote ? (
                <Grid>
                  <Cell palm="12/12">
                    <ThrottledTextField
                      fullWidth
                      multiline
                      disabled={validated}
                      label={formatMessage(messages.invoiceNote)}
                      margin="dense"
                      maxRows={30}
                      minRows={2}
                      value={task.invoiceNote}
                      variant="outlined"
                      onChange={this.handleInvoiceNoteChange}
                    />
                  </Cell>
                </Grid>
              ) : null}
              <Grid>
                <Cell palm="12/12">
                  <ThrottledTextField
                    fullWidth
                    multiline
                    disabled={
                      validated ||
                      (!!userIsNotManager &&
                        (!userIsSeniorMachineOperator ||
                          !customerSettings.seniorMachineOperatorCanEditNotesFromManager))
                    }
                    label={formatMessage(messages.notesFromAdministration)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    value={task.notesFromManager}
                    variant="outlined"
                    onChange={this.handleNotesFromManagerChange}
                  />
                </Cell>
              </Grid>
              {allowMoreThanTwoMachines &&
              hasMultipleManualDistributionPriceItemUses(
                task,
                this.props.priceItemLookup,
                this.props.unitLookup,
              ) ? (
                <Grid>
                  <Cell>
                    <FormattedMessage
                      defaultMessage="Fordeling af effektiv tid"
                      id="order-instance.task-instance.TimeDistribution"
                      tagName="h4"
                    />
                    <TimeDistributionTable
                      expectedMinutes={effectiveMinutes}
                      task={
                        task as Task & {
                          readonly priceItemUses: PriceItemUsesDict;
                        }
                      }
                    />
                  </Cell>
                </Grid>
              ) : null}
              {!userIsNotManager ? (
                <Grid>
                  <Cell>
                    {customerSettings.materialUseAlternativeText ? (
                      <FormattedMessage
                        defaultMessage="Materialer:"
                        id="order-instance.alternative-header.products"
                        tagName="h4"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Materiel:"
                        id="order-instance.header.products"
                        tagName="h4"
                      />
                    )}
                    <TaskPriceItemTable
                      readonly={
                        task.validatedAndRecorded ||
                        (userIsNotManager && (completed || userIsOtherMachineOperator))
                      }
                      readonlyPriceItems={readonlyPriceItems}
                      task={
                        task as Task & {
                          readonly priceItemUses: PriceItemUsesDict;
                        }
                      }
                    />
                    <TaskProductTable
                      hasActivity={hasActivity}
                      productDialogTimerMinutesMap={timerMinutes}
                      reportingSpecification={reportingSpecification}
                      task={
                        task as Task & {
                          readonly priceItemUses: PriceItemUsesDict;
                          readonly productUses: ProductUsesDict;
                        }
                      }
                    />
                    {addProductButton}
                  </Cell>
                </Grid>
              ) : null}
              <FieldsCard
                changeDisabled={task.completed}
                customerActiveFieldList={fieldList}
                customerURL={customerURL}
                extraNotes={fieldExtraNotes}
                fieldUseList={taskFieldUseList}
                locationLookup={locationLookup}
                onNotesChange={this.handleNotesChange}
                onRearrange={this.handleRearrange}
                onSelectFieldsClick={this.handleSelectFieldsClick}
              />
            </CardContent>
            <CardActions>
              {!userIsOtherMachineOperator ? ( // FIXME: disable delete for machine operator if any time registered...
                <ErrorColorButton
                  disabled={disableDelete}
                  variant="contained"
                  onClick={this.handleDeleteButton}
                >
                  {formatMessage(messages.deleteTask)}
                </ErrorColorButton>
              ) : null}

              <Button color="primary" variant="contained" onClick={this.handleCopyButton}>
                <FormattedMessage defaultMessage="Opret kopi" id="task-details.label.make-copy" />
              </Button>
            </CardActions>
          </Card>
        </div>
      </PageLayout>
    );
  }
}

interface TaskDetailsContainerOwnProps {
  instance: Task;
}

interface TaskDetailsContainerStateProps {
  contactArray: readonly Contact[];
  currentRole: Role | null;
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  deliveryLocationLookup: (url: DeliveryLocationUrl) => DeliveryLocation | undefined;
  hoursAbsenceArray: readonly HoursAbsence[];
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  locationUseLogArray: readonly LocationUseLog[];
  machineGroupLookup: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
  pickupLocationLookup: (url: PickupLocationUrl) => PickupLocation | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productArray: readonly Product[];
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  productUseLogArray: readonly ProductUseLog[];
  projectLookup: (url: ProjectUrl) => Project | undefined;
  reportingSpecificationArray: readonly ReportingSpecification[];
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  sprayLocationArray: readonly SprayLocation[];
  sprayLogArray: readonly SprayLog[];
  taskArray: readonly Task[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

interface TaskDetailsContainerDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  createOrUpdate: (instance: ResourceTypeUnion) => void;
  dispatch: Dispatch;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

type TaskDetailsContainerProps = TaskDetailsContainerDispatchProps &
  TaskDetailsContainerOwnProps &
  TaskDetailsContainerStateProps;

class TaskDetailsContainer extends PureComponent<TaskDetailsContainerProps> {
  render(): JSX.Element {
    const {timerStartArray} = this.props;
    const task = this.props.instance;

    let computedIntervals;
    if (task.recordedInC5) {
      computedIntervals = task.computedTimeSet;
    } else {
      const taskURL = task.url;
      const taskTimerStartSeq = _.sortBy(
        timerStartArray.filter((instance) => instance.task === taskURL),
        getNormalisedDeviceTimestamp,
      );
      computedIntervals = computeIntervalsTruncated(taskTimerStartSeq);
    }
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );

    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutesMap = computeIntervalSums(intervals, now);

    return (
      <TaskDetails
        computedIntervals={computedIntervals}
        intervals={intervals}
        task={task}
        timerMinutesMap={timerMinutesMap}
        {...this.props}
      />
    );
  }
}

const ConnectedTaskDetailsContainer = connect<
  TaskDetailsContainerStateProps,
  TaskDetailsContainerDispatchProps,
  TaskDetailsContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TaskDetailsContainerStateProps>({
    contactArray: getContactArray,
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    deliveryArray: getDeliveryArray,
    deliveryLocationArray: getDeliveryLocationArray,
    deliveryLocationLookup: getDeliveryLocationLookup,
    hoursAbsenceArray: getHoursAbsenceArray,
    locationArray: getLocationArray,
    locationLookup: getLocationLookup,
    locationUseLogArray: getLocationUseLogArray,
    machineGroupLookup: getMachineGroupLookup,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    pickupArray: getPickupArray,
    pickupLocationArray: getPickupLocationArray,
    pickupLocationLookup: getPickupLocationLookup,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    productArray: getProductArray,
    productGroupLookup: getProductGroupLookup,
    productLookup: getProductLookup,
    productUseLogArray: getProductUseLogArray,
    projectLookup: getProjectLookup,
    reportingSpecificationArray: getReportingSpecificationArray,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    sprayLocationArray: getSprayLocationArray,
    sprayLogArray: getSprayLogArray,
    taskArray: getTaskArray,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    transportLogArray: getTransportLogArray,
    unitLookup: getUnitLookup,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
    yieldDeliveryLocationArray: getYieldDeliveryLocationArray,
    yieldLogArray: getYieldLogArray,
    yieldPickupLocationArray: getYieldPickupLocationArray,
  }),
  (dispatch) => ({
    dispatch,
    ...bindActionCreators(
      {
        create: actions.create,
        createOrUpdate: actions.createOrUpdate,
        go: actions.go,
        remove: actions.remove,
        update: actions.update,
      },
      dispatch,
    ),
  }),
)(TaskDetailsContainer);

function LoadTaskDetails(): JSX.Element {
  const {formatMessage} = useIntl();
  return (
    <DoLoadInstance
      Component={ConnectedTaskDetailsContainer}
      loadingTitle={formatMessage(messages.taskTitle)}
      lookupSelector={getTaskLookup}
      resourceName="task"
    />
  );
}

export default LoadTaskDetails;
