import {Config} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";

export function roleIsManager(role: Role | null): boolean {
  return !!role && role.manager && !role.projectManager;
}

export function roleIsProjectManager(role: Role | null): boolean {
  return !!role && role.manager && role.projectManager;
}

export function roleCanAddProject(
  config: Pick<Config, "canAddProject" | "enableProjects">,
  role: Role | null,
): boolean {
  const {canAddProject, enableProjects} = config;
  return (
    enableProjects &&
    (canAddProject === "all" ||
      (canAddProject === "manager" && (roleIsManager(role) || roleIsProjectManager(role))))
  );
}

export function roleCanEditProject(
  config: Pick<Config, "canEditProjects" | "enableProjects">,
  role: Role | null,
): boolean {
  const {canEditProjects, enableProjects} = config;
  return enableProjects && canEditProjects && (roleIsManager(role) || roleIsProjectManager(role));
}
