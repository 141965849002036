import {OrderUrl} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";
import {getCustomerSettings, getOrderArray, getTaskArray} from "@co-frontend-libs/redux";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {transferTaskSettings} from "shared-integration-customer-settings";
import {useOrderValidationRequiredForTask} from "./use-order-validation-required-for-task";

export function useOrdersWithValidationRequired(): Set<OrderUrl> {
  const taskArray = useSelector(getTaskArray);
  const orderArray = useSelector(getOrderArray);

  const customerSettings = useSelector(getCustomerSettings);
  const {rules: transferTaskRules} = transferTaskSettings(customerSettings);

  const orderValidationRequiredForTask = useOrderValidationRequiredForTask();

  return useMemo((): Set<OrderUrl> => {
    if (transferTaskRules.requireOrderApproval) {
      return new Set<OrderUrl>(orderArray.map(({url}) => url));
    } else {
      return new Set<OrderUrl>(
        taskArray
          .filter(orderValidationRequiredForTask)
          .map(({order}) => order)
          .filter(notNull),
      );
    }
  }, [
    orderArray,
    transferTaskRules.requireOrderApproval,
    orderValidationRequiredForTask,
    taskArray,
  ]);
}
