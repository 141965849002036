import {
  Customer,
  CustomerUrl,
  Location,
  LocationTypeUrl,
  LocationUrl,
  UserUrl,
} from "@co-common-libs/resources";
import {allowLocationCreate} from "@co-common-libs/resources-utils";
import {
  ConnectedLocationDialog,
  LocationDialogOwnProps,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentRole,
  getCustomerSettings,
  getLocationUseLogArray,
} from "@co-frontend-libs/redux";
import {createLocation} from "app-utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LocationCreateEditDialog} from "./location-create-edit-dialog";

type LocationSelectCreateDialogProps = Omit<
  LocationDialogOwnProps,
  "includeLogOnlyLocations" | "includeWorkplaceOnlyLocations" | "lastUsedLocations"
> & {
  customerLookup?: ((url: CustomerUrl) => Customer | undefined) | undefined;
  includeLogOnlyLocations?: boolean;
  includeWorkplaceOnlyLocations?: boolean;
  logOnlyLocation?: boolean;
  machineOperator?: UserUrl | null | undefined;
};

export const LocationSelectCreateDialog = React.memo(function LocationSelectCreateDialog({
  customerLookup,
  customerURL,
  hideFieldLocations,
  includeLogOnlyLocations = true,
  includeWorkplaceOnlyLocations = true,
  logOnlyLocation,
  machineOperator,
  onCancel,
  onNone,
  onOk,
  open,
  titleVariant,
}: LocationSelectCreateDialogProps): JSX.Element | null {
  const dispatch = useDispatch();

  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);
  const [locationAddDialogOpen, setLocationAddDialogOpen] = useState(false);
  const [locationAddDialogInitialAddress, setLocationAddDialogInitialAddress] = useState("");

  const handleOnWorkPlaceAdd = useCallback((searchString: string): void => {
    setLocationAddDialogInitialAddress(searchString);
    setLocationAddDialogOpen(true);
  }, []);

  const handleLocationCreateEditDialogCancel = useCallback((): void => {
    setLocationAddDialogOpen(false);
    setLocationAddDialogInitialAddress("");
  }, []);

  const handleLocationAddDialogOk = useCallback(
    (data: {
      address: string;
      attention: string;
      city: string;
      coordinatesFromAddress: boolean;
      customer: CustomerUrl | null;
      favorite: boolean;
      latitude: number | null;
      locationType: LocationTypeUrl | null;
      logOnlyLocation: boolean;
      longitude: number | null;
      name: string;
      phone: string;
      postalCode: string;
      workplaceOnlyLocation: boolean;
    }): void => {
      setLocationAddDialogOpen(false);
      setLocationAddDialogInitialAddress("");
      const {
        address,
        attention,
        city,
        coordinatesFromAddress,
        customer,
        favorite,
        latitude,
        locationType,
        longitude,
        name,
        phone,
        postalCode,
        workplaceOnlyLocation,
      } = data;
      const instance: Location = createLocation({
        address,
        attention,
        city,
        coordinatesFromAddress,
        customer,
        favorite,
        latitude,
        locationType,
        logOnlyLocation: data.logOnlyLocation,
        longitude,
        name,
        phone,
        postalCode,
        workplaceOnlyLocation,
      });
      dispatch(actions.create(instance));
      setTimeout(() => {
        onOk(instance.url);
      });
    },
    [dispatch, onOk],
  );

  const handleLocationDialogOk = useCallback(
    (location: LocationUrl): void => {
      onOk(location);
    },
    [onOk],
  );
  const locationUseLogArray = useSelector(getLocationUseLogArray);
  const lastUsedLocations = useMemo(() => {
    if (customerURL && machineOperator) {
      const locationUse = locationUseLogArray.find(
        (l) => l.customer === customerURL && l.user === machineOperator,
      );
      return new Set(locationUse?.locations);
    }
    return undefined;
  }, [customerURL, locationUseLogArray, machineOperator]);

  return (
    <>
      <ConnectedLocationDialog
        customerURL={customerURL}
        hideFieldLocations={hideFieldLocations}
        includeLogOnlyLocations={includeLogOnlyLocations}
        includeWorkplaceOnlyLocations={includeWorkplaceOnlyLocations}
        lastUsedLocations={lastUsedLocations}
        open={open && !locationAddDialogOpen}
        titleVariant={titleVariant}
        onAdd={
          allowLocationCreate(customerSettings, currentRole) && customerURL
            ? handleOnWorkPlaceAdd
            : undefined
        }
        onCancel={onCancel}
        onNone={onNone}
        onOk={handleLocationDialogOk}
      />
      <LocationCreateEditDialog
        customerLookup={customerLookup}
        initialCustomer={customerURL}
        initialSearch={locationAddDialogInitialAddress}
        logOnlyLocation={logOnlyLocation ?? customerSettings.setLogOnlyLocationOnCreate}
        open={locationAddDialogOpen}
        workplaceOnlyLocation={
          customerSettings.setWorkplaceOnlyLocationOnCreate && logOnlyLocation === false
        }
        onCancel={handleLocationCreateEditDialogCancel}
        onOk={handleLocationAddDialogOk}
      />
    </>
  );
});
