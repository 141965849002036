import {
  CustomerUrl,
  Location,
  LocationTypeUrl,
  LocationUrl,
  Patch,
  urlToId,
} from "@co-common-libs/resources";
import {
  allowLocationCreate,
  allowLocationEdit,
  allowWorkplaceEdit,
} from "@co-common-libs/resources-utils";
import {
  ResponsiveDialog,
  SpinnerDialog,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {createLocation, useFalseCallback} from "app-utils";
import {globalConfig} from "frontend-global-config";
import MergeIcon from "mdi-react/MergeIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {LocationCreateEditDialog} from "../location-create-edit-dialog";
import {LocationMergeDialog} from "../location-merge-dialog";
import {LocationTable} from "./location-table";

const MINIMUM_SELECTED_LOCATIONS_FOR_MERGE = 2;

async function changeLocationCustomer(locationURL: string, customerURL: string): Promise<void> {
  const {baseURL} = globalConfig.resources;
  const url = `${baseURL}location_change_customer/${urlToId(locationURL)}`;
  await jsonFetch(url, "POST", {
    locationURL,
    newCustomerId: urlToId(customerURL),
    newCustomerUrl: customerURL,
  });
}

interface LocationListProps {
  customerURL?: CustomerUrl;
  showInactive: boolean;
}

function useLocationSelection(): [
  ReadonlySet<LocationUrl>,
  CustomerUrl | null | undefined,
  (locationURL: LocationUrl, selected: boolean) => void,
  () => void,
] {
  const locationLookup = useSelector(getLocationLookup);
  const [selectedLocations, setSelectedLocations] = useState<ReadonlySet<LocationUrl>>(
    new Set<LocationUrl>(),
  );
  const handleSelectLocation = useCallback(
    (locationURL: LocationUrl, selected: boolean) => {
      const newSelectedLocations = new Set(selectedLocations);
      if (selected) {
        newSelectedLocations.add(locationURL);
        setSelectedLocations(newSelectedLocations);
      } else {
        newSelectedLocations.delete(locationURL);
        setSelectedLocations(newSelectedLocations);
      }
    },
    [selectedLocations],
  );
  const clearSelectedLocations = useCallback(() => {
    setSelectedLocations(new Set<LocationUrl>());
  }, []);

  const firstLocationUrl = useMemo(
    () => (selectedLocations.size ? selectedLocations.values().next().value : undefined),
    [selectedLocations],
  );
  const firstLocation = useMemo(
    () => (firstLocationUrl ? locationLookup(firstLocationUrl) : undefined),
    [firstLocationUrl, locationLookup],
  );

  const selectedCustomer = firstLocation?.customer;

  return [selectedLocations, selectedCustomer, handleSelectLocation, clearSelectedLocations];
}

export function LocationList(props: LocationListProps): JSX.Element {
  const {customerURL, showInactive} = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const locationLookup = useSelector(getLocationLookup);

  const [selectedLocations, selectedCustomer, handleSelectLocation, clearSelectedLocations] =
    useLocationSelection();

  const [locationMergeDialogOpen, setLocationMergeDialogOpen] = useState(false);
  const handleLocationMergeClick = useCallback(() => {
    setLocationMergeDialogOpen(true);
  }, []);
  const handleLocationMergeDialogCancel = useCallback(() => {
    setLocationMergeDialogOpen(false);
  }, []);
  const handleLocationMergeDialogOk = useCallback(
    (locationURL: LocationUrl) => {
      setSpinnerDialogOpen(true);
      setLocationMergeDialogOpen(false);
      const {baseURL} = globalConfig.resources;
      const url = `${baseURL}merge_locations`;
      const locations = Array.from(selectedLocations).filter((l) => l !== locationURL);
      jsonFetch(url, "POST", {
        location: urlToId(locationURL),
        locations: locations.map((l) => urlToId(l)),
      })
        .then(() => {
          locations.forEach((location) => {
            dispatch(
              actions.update(location, [
                {member: "active", value: false},
                {member: "mergedTo", value: locationURL},
              ]),
            );
          });
          setSpinnerDialogOpen(false);
          clearSelectedLocations();
          return;
        })
        .catch(() => {
          setSpinnerDialogOpen(false);
          setErrorDialogOpen(true);
        });
    },
    [clearSelectedLocations, dispatch, selectedLocations],
  );
  const [spinnerDialogOpen, setSpinnerDialogOpen] = useState(false);
  const [spinnerMoveDialogOpen, setSpinnerMoveDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const handleErrorDialogOk = useCallback(() => {
    setErrorDialogOpen(false);
  }, []);

  const [locationEditDialogURL, setLocationEditDialogURL] = useState<LocationUrl | null>(null);
  const [locationCreateDialogOpen, setLocationCreateDialogOpen] = useState<boolean>(false);
  const handleAddFab = useCallback(() => setLocationCreateDialogOpen(true), []);
  const handleLocationCreateEditDialogCancel = useCallback(() => {
    setLocationEditDialogURL(null);
    setLocationCreateDialogOpen(false);
  }, []);

  const [errorMoveDialogOpen, setErrorMoveDialogOpen] = useState(false);

  const handleExtendedLocationCreateEditDialogOk = useCallback(
    ({
      active,
      address,
      attention,
      city,
      coordinatesFromAddress,
      customer,
      customerChanged,
      favorite,
      latitude,
      locationType,
      logOnlyLocation,
      longitude,
      name,
      phone,
      postalCode,
      workplaceOnlyLocation,
    }: {
      active: boolean;
      address: string;
      attention: string;
      city: string;
      coordinatesFromAddress: boolean;
      customer: CustomerUrl | null;
      customerChanged?: boolean;
      favorite: boolean;
      latitude: number | null;
      locationType: LocationTypeUrl | null;
      logOnlyLocation: boolean;
      longitude: number | null;
      name: string;
      phone: string;
      postalCode: string;
      workplaceOnlyLocation: boolean;
    }): void => {
      if (locationEditDialogURL) {
        const updateData: Patch<Location> = [
          {member: "active", value: active},
          {member: "address", value: address},
          {member: "attention", value: attention},
          {member: "city", value: city},
          {member: "customer", value: customer},
          {member: "favorite", value: favorite},
          {member: "latitude", value: latitude},
          {member: "locationType", value: locationType},
          {member: "logOnlyLocation", value: logOnlyLocation},
          {member: "longitude", value: longitude},
          {member: "name", value: name},
          {member: "phone", value: phone},
          {member: "postalCode", value: postalCode},
          {member: "workplaceOnlyLocation", value: workplaceOnlyLocation},
          {member: "coordinatesFromAddress", value: coordinatesFromAddress},
        ];
        dispatch(actions.update(locationEditDialogURL, updateData));
        if (customerChanged && customer) {
          setSpinnerMoveDialogOpen(true);
          changeLocationCustomer(locationEditDialogURL, customer)
            .then(() => {
              setSpinnerMoveDialogOpen(false);
              return;
            })
            .catch(() => {
              setSpinnerMoveDialogOpen(false);
              setErrorMoveDialogOpen(true);
            });
        }
      } else {
        const instance: Location = createLocation({
          active,
          address,
          attention,
          city,
          coordinatesFromAddress,
          customer: customerURL || customer,
          favorite,
          latitude,
          locationType,
          logOnlyLocation,
          longitude,
          name,
          phone,
          postalCode,
          workplaceOnlyLocation,
        });
        dispatch(actions.create(instance));
      }
      setLocationEditDialogURL(null);
      setLocationCreateDialogOpen(false);
    },
    [customerURL, dispatch, locationEditDialogURL],
  );

  const currentRole = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);

  const locationList = useMemo(() => Array.from(selectedLocations), [selectedLocations]);
  const handleReadOnlyLocationDialogOk = useCallback(() => setLocationEditDialogURL(null), []);

  const handleMoveErrorDialogOk = useFalseCallback(setErrorMoveDialogOpen, [
    setErrorMoveDialogOpen,
  ]);
  const editLocation = locationEditDialogURL ? locationLookup(locationEditDialogURL) : null;
  const attemptingToEditReadonlyLocation =
    !!editLocation &&
    !allowWorkplaceEdit(customerSettings, currentRole) &&
    !editLocation.logOnlyLocation;
  const addFab = allowLocationCreate(customerSettings, currentRole) ? (
    <VerticalStackingFloatingActionButton stackIndex={0} onClick={handleAddFab}>
      <PlusIcon />
    </VerticalStackingFloatingActionButton>
  ) : null;
  const mergeFab = allowLocationEdit(customerSettings, currentRole) ? (
    <VerticalStackingFloatingActionButton
      disabled={selectedLocations.size < MINIMUM_SELECTED_LOCATIONS_FOR_MERGE}
      stackIndex={allowLocationCreate(customerSettings, currentRole) ? 1 : 0}
      onClick={handleLocationMergeClick}
    >
      <MergeIcon />
    </VerticalStackingFloatingActionButton>
  ) : null;

  return (
    <>
      <LocationTable
        customerURL={customerURL}
        selectedCustomer={selectedCustomer}
        selectedLocations={selectedLocations}
        showInactive={showInactive}
        onClick={
          allowLocationEdit(customerSettings, currentRole) ? setLocationEditDialogURL : undefined
        }
        onSelect={handleSelectLocation}
      />
      {addFab}
      <ResponsiveDialog
        open={attemptingToEditReadonlyLocation}
        title={intl.formatMessage({defaultMessage: "Kan ikke redigeres"})}
        onOk={handleReadOnlyLocationDialogOk}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Dette sted kan kun redigeres i jeres økonomisystem" />
        </DialogContent>
      </ResponsiveDialog>
      <LocationCreateEditDialog
        key="workplace-create-dialog"
        customerLookup={customerURL ? undefined : customerLookup}
        location={editLocation || undefined}
        open={
          locationCreateDialogOpen || (!!locationEditDialogURL && !attemptingToEditReadonlyLocation)
        }
        onCancel={handleLocationCreateEditDialogCancel}
        onOk={handleExtendedLocationCreateEditDialogOk}
      />
      {mergeFab}
      <LocationMergeDialog
        locationList={locationList}
        locationLookup={locationLookup}
        open={locationMergeDialogOpen}
        onCancel={handleLocationMergeDialogCancel}
        onOk={handleLocationMergeDialogOk}
      />
      <SpinnerDialog
        open={spinnerDialogOpen}
        title={<FormattedMessage defaultMessage="Fletter steder" id="location-list.merging" />}
      />
      <SpinnerDialog
        open={spinnerMoveDialogOpen}
        title={
          <FormattedMessage defaultMessage="Ændrer kunde på stedet" id="location-list.moving" />
        }
      />
      <ResponsiveDialog
        open={errorDialogOpen}
        title={<FormattedMessage defaultMessage="Fejl" id="location-list.error" />}
        onOk={handleErrorDialogOk}
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="Der opstod en fejl under fletningen, prøv igen senere, hvis fejlen ikke forsvinder, så kontakt support"
            id="location-list.error-message"
          />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={errorMoveDialogOpen}
        title={<FormattedMessage defaultMessage="Fejl" id="location-list.error" />}
        onOk={handleMoveErrorDialogOk}
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="Der opstod en fejl under flytningen, kontakt support"
            id="location-list.move-error-message"
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
