import {Task} from "@co-common-libs/resources";
import {getCustomerSettings, getOrderLookup, getWorkTypeLookup} from "@co-frontend-libs/redux";
import _ from "lodash";
import {useCallback} from "react";
import {useSelector} from "react-redux";
import {transferTaskSettings} from "shared-integration-customer-settings";

export function useOrderValidationRequiredForTask(): (
  task: Pick<Task, "order" | "url" | "workType">,
) => boolean {
  const customerSettings = useSelector(getCustomerSettings);
  const {rules: transferTaskRules} = transferTaskSettings(customerSettings);

  const orderLookup = useSelector(getOrderLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  return useCallback(
    (task: Pick<Task, "order" | "url" | "workType">): boolean => {
      if (transferTaskRules.requireOrderApproval) {
        return true;
      } else if (!_.isEmpty(transferTaskRules.requireOrderApprovalForWorkTypes)) {
        if (!task.order || !task.workType) {
          return false;
        }

        const order = orderLookup(task.order);
        const workType = workTypeLookup(task.workType);

        return (
          !order?.routePlan &&
          !!(
            workType &&
            transferTaskRules.requireOrderApprovalForWorkTypes.includes(workType.identifier)
          )
        );
      } else {
        return false;
      }
    },
    [
      orderLookup,
      transferTaskRules.requireOrderApproval,
      transferTaskRules.requireOrderApprovalForWorkTypes,
      workTypeLookup,
    ],
  );
}
