import {Customer, CustomerUrl, Location, LocationTypeUrl} from "@co-common-libs/resources";
import {allowWorkplaceCreate, allowWorkplaceEdit} from "@co-common-libs/resources-utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {ConnectedLocationTypeDialog} from "@co-frontend-libs/connected-components";
import {
  getCurrentRole,
  getCustomerSettings,
  getLocationTypeArray,
  getLocationTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {
  Button,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  useTheme,
} from "@material-ui/core";
import {
  postalCodes,
  useEventTargetCheckedCallback,
  useFalseCallback,
  useLoadGoogleMaps,
  useTrueCallback,
} from "app-utils";
import {SPACING} from "frontend-global-config";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import React, {ChangeEvent, useCallback, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ChangeLocationCustomerDialog} from "../change-location-customer-dialog";
import {CustomerSelectCreateDialog} from "../customer-select-create-dialog";
import {AddressAutocompleteField} from "./address-autocomplete-field";
import {FullWindowLocationMap} from "./full-window-location-map";

// The following three props interfaces are combined below to the actual
// LocationCreateEditDialogProps interface, as a quickfix to make TypeScript
// catch misuse of this component.

export interface LocationCreateData {
  active: boolean;
  address: string;
  attention: string;
  city: string;
  coordinatesFromAddress: boolean;
  customer: CustomerUrl | null;
  customerChanged?: boolean;
  favorite: boolean;
  latitude: number | null;
  locationType: LocationTypeUrl | null;
  logOnlyLocation: boolean;
  longitude: number | null;
  name: string;
  phone: string;
  postalCode: string;
  workplaceOnlyLocation: boolean;
}

interface LocationCreateEditDialogCommonProps {
  customerLookup?: (url: CustomerUrl) => Customer | undefined;
  initialCustomer?: CustomerUrl | null;
  initialSearch?: string;
  locationFavoritesEnabled?: boolean;
  onCancel: () => void;
  onOk: (data: LocationCreateData) => void;
  open: boolean;
}

export interface LocationCreateEditDialogWorkplaceCreateProps
  extends LocationCreateEditDialogCommonProps {
  workplaceOnlyLocation?: boolean;
}

export interface LocationCreateEditDialogLogCreateProps
  extends LocationCreateEditDialogCommonProps {
  logOnlyLocation?: boolean;
}

export interface LocationCreateEditDialogEditProps extends LocationCreateEditDialogCommonProps {
  location: Readonly<Location> | undefined;
}

export type LocationCreateEditDialogProps =
  | LocationCreateEditDialogEditProps
  | LocationCreateEditDialogLogCreateProps
  | LocationCreateEditDialogWorkplaceCreateProps;

export const LocationCreateEditDialog = React.memo(function LocationCreateEditDialog(
  props: LocationCreateEditDialogProps,
): JSX.Element {
  const {customerLookup, initialCustomer, initialSearch, onCancel, onOk, open} = props;
  const customerSettings = useSelector(getCustomerSettings);

  const location = (props as Partial<LocationCreateEditDialogEditProps>)?.location;
  const logOnly =
    (props as Partial<LocationCreateEditDialogLogCreateProps>)?.logOnlyLocation ??
    customerSettings.setLogOnlyLocationOnCreate;
  const workplaceOnly =
    (props as Partial<LocationCreateEditDialogWorkplaceCreateProps>)?.workplaceOnlyLocation ??
    customerSettings.setWorkplaceOnlyLocationOnCreate;

  const currentRole = useSelector(getCurrentRole);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const locationTypeArray = useSelector(getLocationTypeArray);

  const locationFavoritesEnabled =
    props.locationFavoritesEnabled ?? customerSettings.locationFavoritesEnabled;

  const [placesSearch, setPlacesSearch] = useResettingState(initialSearch || "", open);
  const [active, setActive] = useResettingState(location?.active ?? true, open);
  const [address, setAddress] = useResettingState(location?.address || "", open);
  const [city, setCity] = useResettingState(location?.city || "", open);
  const [attention, setAttention] = useResettingState(location?.attention || "", open);
  const [phone, setPhone] = useResettingState(location?.phone || "", open);
  const [customer, setCustomer] = useResettingState(
    location?.customer || initialCustomer || null,
    open,
  );
  const [customerDialogOpen, setCustomerDialogOpen] = useResettingState(false, open);
  const [favorite, setFavorite] = useResettingState(location?.favorite || false, open);

  const [locationType, setLocationType] = useResettingState(
    location?.locationType ||
      locationTypeArray.find((instance) => instance.identifier === "standard")?.url ||
      null,
    open,
  );
  const [locationTypeDialogOpen, setLocationTypeDialogOpen] = useResettingState(false, open);
  const [name, setName] = useResettingState(location?.name || "", open);
  const [postalCode, setPostalCode] = useResettingState(location?.postalCode || "", open);
  const [latitude, setLatitude] = useResettingState(location?.latitude || null, open);
  const [longitude, setLongitude] = useResettingState(location?.longitude || null, open);
  const [centerLatitude, setCenterLatitude] = useResettingState(
    latitude || customerSettings.geolocation.initialPositionLatitude,
    open,
  );
  const [centerLongitude, setCenterLongitude] = useResettingState(
    longitude || customerSettings.geolocation.initialPositionLongitude,
    open,
  );
  const [logOnlyLocation, setLogOnlyLocation] = useResettingState(
    location?.logOnlyLocation ?? logOnly ?? false,
    open,
  );

  const [workplaceOnlyLocation, setWorkplaceOnlyLocation] = useResettingState(
    location?.workplaceOnlyLocation ?? workplaceOnly ?? false,
    open,
  );
  const [coordinatesFromAddress, setCoordinatesFromAddress] = useResettingState(
    location?.coordinatesFromAddress ?? false,
    open,
  );

  const handleOk = useCallback(() => {
    onOk({
      active,
      address: address.trim(),
      attention,
      city,
      coordinatesFromAddress,
      customer,
      customerChanged: !!location?.customer && customer !== location?.customer,
      favorite,
      latitude,
      locationType,
      logOnlyLocation,
      longitude,
      name,
      phone,
      postalCode,
      workplaceOnlyLocation,
    });
  }, [
    onOk,
    active,
    address,
    attention,
    city,
    coordinatesFromAddress,
    customer,
    location?.customer,
    favorite,
    latitude,
    locationType,
    logOnlyLocation,
    longitude,
    name,
    phone,
    postalCode,
    workplaceOnlyLocation,
  ]);

  const handlePostalCodeChange = useCallback(
    (value: string) => {
      setPostalCode(value);
      const cityForPostalCode = postalCodes[value];
      if (cityForPostalCode) {
        setCity(cityForPostalCode);
      }
    },
    [setCity, setPostalCode],
  );

  const handleActiveChange = useEventTargetCheckedCallback(setActive, [setActive]);

  const handleLocationOnlyChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: string) => {
      if (value === "both") {
        setLogOnlyLocation(false);
        setWorkplaceOnlyLocation(false);
      } else if (value === "logOnly") {
        setLogOnlyLocation(true);
        setWorkplaceOnlyLocation(false);
      } else {
        setWorkplaceOnlyLocation(true);
        setLogOnlyLocation(false);
      }
    },
    [setLogOnlyLocation, setWorkplaceOnlyLocation],
  );

  const handleCustomerButtonClick = useTrueCallback(setCustomerDialogOpen, [setCustomerDialogOpen]);

  const handleLocationTypeButtonClick = useTrueCallback(setLocationTypeDialogOpen, [
    setLocationTypeDialogOpen,
  ]);

  const okDisabled = !(
    locationType &&
    (name || address || postalCode || city) &&
    (customer || !customerLookup)
  );

  const intl = useIntl();
  let locationOnlyChoice;
  if (
    (location && allowWorkplaceEdit(customerSettings, currentRole)) ||
    (!location &&
      allowWorkplaceCreate(customerSettings, currentRole) &&
      workplaceOnly === undefined &&
      logOnly === undefined)
  ) {
    locationOnlyChoice = (
      <FormControl component="fieldset">
        <RadioGroup
          value={logOnlyLocation ? "logOnly" : workplaceOnlyLocation ? "workplaceOnly" : "both"}
          onChange={handleLocationOnlyChange}
        >
          <FormControlLabel
            control={<Radio />}
            label={intl.formatMessage({
              defaultMessage: "Kan bruges som både arbejdssted og logsted",
            })}
            value="both"
          />
          <FormControlLabel
            control={<Radio />}
            label={intl.formatMessage({
              defaultMessage: "Kan kun bruges som arbejdssted",
            })}
            value="workplaceOnly"
          />
          <FormControlLabel
            control={<Radio />}
            label={intl.formatMessage({
              defaultMessage: "Kan kun bruges som logsted",
            })}
            value="logOnly"
          />
        </RadioGroup>
      </FormControl>
    );
  }

  const handleFavoriteChange = useEventTargetCheckedCallback(setFavorite, [setFavorite]);

  let favoriteToggle: JSX.Element | undefined;
  if (locationFavoritesEnabled) {
    favoriteToggle = (
      <div>
        <FormControlLabel
          control={<Switch checked={!!favorite} onChange={handleFavoriteChange} />}
          label={intl.formatMessage({defaultMessage: "Favorit"})}
          labelPlacement="end"
        />
      </div>
    );
  }

  const handleLocationTypeDialogOk = useCallback(
    (locationTypeURL: LocationTypeUrl) => {
      setLocationType(locationTypeURL);
      setLocationTypeDialogOpen(false);
    },
    [setLocationType, setLocationTypeDialogOpen],
  );
  const handleCustomerDialogOk = useCallback(
    (customerURL: CustomerUrl | null) => {
      setCustomer(customerURL);
      setCustomerDialogOpen(false);
    },
    [setCustomer, setCustomerDialogOpen],
  );

  const handleLocationChange = useCallback(
    (newLatitude: number | null, newLongitude: number | null) => {
      setLatitude(newLatitude);
      setLongitude(newLongitude);
      setCoordinatesFromAddress(false);
    },
    [setCoordinatesFromAddress, setLatitude, setLongitude],
  );

  const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | undefined>();

  const geocoderRef = useRef<google.maps.Geocoder | undefined>();

  const {isLoaded: mapLoaded} = useLoadGoogleMaps();

  if (mapLoaded && !autocompleteServiceRef.current) {
    autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
  }
  if (mapLoaded && !geocoderRef.current) {
    geocoderRef.current = new google.maps.Geocoder();
  }

  const [fullWindowMapOpen, setFullWindowMapOpen] = useState(false);
  const setFullWindowMapOpenTrue = useCallWithTrue(setFullWindowMapOpen);
  const setFullWindowMapOpenFalse = useCallWithFalse(setFullWindowMapOpen);

  const handleGeocodeResult = useCallback(
    (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
      if (status === google.maps.GeocoderStatus.OK && results) {
        let foundStreetAddress = "";
        let foundPostalCode = "";
        let foundPostalTown = "";
        let foundStreetNumber = "";
        let foundSublocality = "";
        let foundLocality = "";
        const result = results[0];
        const geometryLocation = result.geometry.location;
        const lat = geometryLocation.lat();
        const lng = geometryLocation.lng();
        setLatitude(lat);
        setLongitude(lng);
        setCenterLatitude(lat);
        setCenterLongitude(lng);
        setCoordinatesFromAddress(true);
        result.address_components.forEach((addressComponent) => {
          if (process.env.NODE_ENV !== "production") {
            // eslint-disable-next-line no-console
            console.log("found address:");
            // eslint-disable-next-line no-console
            console.log(addressComponent);
          }
          const {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            long_name: longName,
            types,
            /* short_name */
          } = addressComponent;
          types.forEach((type) => {
            switch (type) {
              case "route":
                foundStreetAddress = longName;
                break;
              case "street_address":
                foundStreetAddress = longName;
                break;
              case "postal_code":
                foundPostalCode = longName;
                break;
              case "postal_town":
                foundPostalTown = longName;
                break;
              case "street_number":
                foundStreetNumber = longName;
                break;
              case "sublocality":
                foundSublocality = longName;
                break;
              case "locality":
                foundLocality = longName;
                break;
              default:
                break;
            }
          });
        });
        if (foundPostalCode) {
          setPostalCode(foundPostalCode);
        }
        if (foundPostalTown) {
          setCity(foundPostalTown);
        } else if (foundLocality) {
          setCity(foundLocality);
        }
        const resultAddress =
          `${foundStreetAddress} ${foundStreetNumber}`.trim() || foundSublocality || foundLocality;
        if (resultAddress) {
          setAddress(resultAddress);
        }
      }
    },
    [
      setAddress,
      setCenterLatitude,
      setCenterLongitude,
      setCity,
      setCoordinatesFromAddress,
      setLatitude,
      setLongitude,
      setPostalCode,
    ],
  );

  const handlePlaceSelected = useCallback(
    async (place: google.maps.places.AutocompletePrediction): Promise<void> => {
      if (place.types.includes("point_of_interest") || place.types.includes("establishment")) {
        const resultMainText = place.structured_formatting.main_text;
        setName(resultMainText);
        setPlacesSearch(resultMainText);
      } else {
        setName("");
      }
      if (geocoderRef.current) {
        await geocoderRef.current.geocode({placeId: place.place_id}, handleGeocodeResult);
      }
    },
    [handleGeocodeResult, setName, setPlacesSearch],
  );

  const [changeCustomerDialogOpen, setChangeCustomerDialogOpen] = useState(false);
  const handleChangeCustomerClick = useTrueCallback(setChangeCustomerDialogOpen, [
    setChangeCustomerDialogOpen,
  ]);

  const handleChangeCustomerDialogOk = useCallback(() => {
    setChangeCustomerDialogOpen(false);
    setCustomerDialogOpen(true);
  }, [setCustomerDialogOpen]);

  const userIsManager = currentRole && currentRole.manager;

  const theme = useTheme();

  let customerSelectionBlock;
  let customerActiveOrAbsent = true;
  if (customerLookup) {
    const customerInstance = customer ? customerLookup(customer) : null;
    customerActiveOrAbsent = customerInstance?.active ?? true;
    if (location) {
      customerSelectionBlock = (
        <div>
          <Button color="secondary" variant="contained" onClick={handleChangeCustomerClick}>
            {intl.formatMessage({defaultMessage: "Skift kunde"})}
          </Button>
          <div style={{marginTop: 5}}>
            {customerInstance
              ? customerInstance.name
              : intl.formatMessage({defaultMessage: "Ingen valgt"})}
          </div>
        </div>
      );
    } else {
      customerSelectionBlock = (
        <div>
          <Button color="secondary" variant="contained" onClick={handleCustomerButtonClick}>
            <FormattedMessage defaultMessage="Vælg kunde" />
          </Button>
          <div style={{marginTop: 5}}>
            {customerInstance
              ? customerInstance.name
              : intl.formatMessage({defaultMessage: "Ingen valgt"})}
          </div>
        </div>
      );
    }
  }
  let activeToggle;
  if (location) {
    activeToggle = (
      <div>
        <FormControlLabel
          control={
            <Switch checked={active && customerActiveOrAbsent} onChange={handleActiveChange} />
          }
          disabled={!customerActiveOrAbsent}
          label={intl.formatMessage({defaultMessage: "Aktiv"})}
          labelPlacement="end"
        />
        {!customerActiveOrAbsent ? (
          <FormattedMessage
            defaultMessage={"Stedet tilhører en inaktiv kunde og kan derfor ikke gøre aktiv"}
            tagName="div"
          />
        ) : null}
      </div>
    );
  }

  const handleChangeCustomerDialogCancel = useFalseCallback(setChangeCustomerDialogOpen, [
    setChangeCustomerDialogOpen,
  ]);

  const locationTypesExist = !!locationTypeArray.length;

  const title = location
    ? intl.formatMessage({defaultMessage: "Redigér sted"})
    : intl.formatMessage({defaultMessage: "Opret arbejdssted"});

  return (
    <>
      <ResponsiveDialog
        okDisabled={okDisabled}
        open={open && !locationTypeDialogOpen && !customerDialogOpen && !changeCustomerDialogOpen}
        title={title}
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs>
              <AddressAutocompleteField
                autocompleteService={autocompleteServiceRef.current}
                autoFocus={!location}
                countryRestrictions={customerSettings.googleMapsCountryRestrictions}
                openOnFocus={!!initialSearch}
                value={placesSearch}
                onChange={setPlacesSearch}
                onPlaceSelected={handlePlaceSelected}
              />
            </Grid>
            <Grid item>
              <IconButton
                color={latitude != null && longitude != null ? "primary" : "default"}
                onClick={setFullWindowMapOpenTrue}
              >
                <MapMarkerIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider
            style={{
              marginBottom: theme.spacing(SPACING.SMALL),
              marginTop: theme.spacing(SPACING.SMALL),
            }}
          />

          <TrimTextField
            fullWidth
            label={intl.formatMessage({defaultMessage: "Navn"})}
            margin="dense"
            value={name}
            variant="outlined"
            onChange={setName}
          />
          <TrimTextField
            fullWidth
            label={intl.formatMessage({defaultMessage: "Adresse"})}
            margin="dense"
            value={address}
            variant="outlined"
            onChange={setAddress}
          />
          <TrimTextField
            fullWidth
            inputProps={{maxLength: 15}}
            label={intl.formatMessage({defaultMessage: "Postnr."})}
            margin="dense"
            value={postalCode}
            variant="outlined"
            onChange={handlePostalCodeChange}
          />
          <TrimTextField
            fullWidth
            label={intl.formatMessage({defaultMessage: "By"})}
            margin="dense"
            value={city}
            variant="outlined"
            onChange={setCity}
          />
          <TrimTextField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Kontaktperson",
            })}
            margin="dense"
            value={attention}
            variant="outlined"
            onChange={setAttention}
          />
          <TrimTextField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Telefonnr.",
            })}
            margin="dense"
            value={phone}
            variant="outlined"
            onChange={setPhone}
          />
          {customerSelectionBlock}
          {locationTypesExist &&
          (userIsManager || customerSettings.allowMachineOperatorToEditLocationLocationType) &&
          !location?.geojson ? (
            <div style={{marginTop: 10}}>
              <Button color="secondary" variant="contained" onClick={handleLocationTypeButtonClick}>
                <FormattedMessage defaultMessage="Vælg stedtype" />
              </Button>
              <div style={{marginTop: 5}}>
                {locationType
                  ? locationTypeLookup(locationType)?.name ||
                    locationTypeLookup(locationType)?.identifier
                  : intl.formatMessage({defaultMessage: "Ingen valgt"})}
              </div>
            </div>
          ) : null}
          {favoriteToggle}
          {locationOnlyChoice}
          {activeToggle}
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedLocationTypeDialog
        open={locationTypeDialogOpen}
        onCancel={useFalseCallback(setLocationTypeDialogOpen, [setLocationTypeDialogOpen])}
        onOk={handleLocationTypeDialogOk}
      />
      {location?.url ? (
        <ChangeLocationCustomerDialog
          locationUrl={location.url}
          open={changeCustomerDialogOpen}
          onCancel={handleChangeCustomerDialogCancel}
          onOk={handleChangeCustomerDialogOk}
        />
      ) : null}
      <CustomerSelectCreateDialog
        open={customerDialogOpen}
        onCancel={useFalseCallback(setCustomerDialogOpen, [setCustomerDialogOpen])}
        onOk={handleCustomerDialogOk}
      />
      <FullWindowLocationMap
        centerLatitude={centerLatitude}
        centerLongitude={centerLongitude}
        latitude={latitude}
        longitude={longitude}
        mapLoaded={mapLoaded}
        open={fullWindowMapOpen}
        onClose={setFullWindowMapOpenFalse}
        onLocationChange={handleLocationChange}
      />
    </>
  );
});
